import { InfoTooltip } from "@decentriq/components";
import { Box, Checkbox, Typography } from "@mui/joy";
import { memo } from "react";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";
import ExcludeSeedAudienceInfoDialog from "../ExcludeSeedAudienceInfoDialog/ExcludeSeedAudienceInfoDialog";

const ExcludeSeedAudienceCheckbox: React.FC = () => {
  const { setExcludeSeedAudience, excludeSeedAudience } =
    useLookalikeAdvertiserAudienceGenerator();
  return (
    <>
      <Box width="fit-content">
        <Checkbox
          checked={excludeSeedAudience}
          // TODO: Add disabled state if selected audience has no seed audience
          disabled={false}
          label={
            <Typography level="body-md">
              Exclude seed audience from your new audience
              <InfoTooltip
                tooltip="Checking this box will remove any individuals in your data
                from the generated audience. For privacy reasons, all
                audiences shared with a publisher must be of the same type
                (excluded / included)."
              />
            </Typography>
          }
          onChange={(event) => setExcludeSeedAudience(event.target.checked)}
          sx={{ ml: 0, width: "fit-content" }}
        />
      </Box>
      <ExcludeSeedAudienceInfoDialog />
    </>
  );
};

ExcludeSeedAudienceCheckbox.displayName = "ExcludeSeedAudienceCheckbox";

export default memo(ExcludeSeedAudienceCheckbox);
