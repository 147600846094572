import { DqParticipantsEditor } from "@decentriq/components";
import { useAvailablePublishersQuery } from "@decentriq/graphql/dist/hooks";
import { faBullhorn, faNewspaper } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Autocomplete,
  Chip,
  ChipDelete,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { uniqBy } from "lodash";
import { memo, useMemo } from "react";
import {
  Controller,
  type ControllerRenderProps,
  type FieldError,
  useFormContext,
} from "react-hook-form";
import { type ZodTooBigIssue } from "zod";

const groupNestedErrorsIndexesByMessage = (error: FieldError) =>
  Object.entries(error).reduce(
    (memo: Record<string, number[]>, [index, error]) => {
      if (Number.isInteger(parseInt(index, 10))) {
        const { message } = error as FieldError;
        if (message) {
          memo[message] = [...(memo[message] || []), parseInt(index, 10)];
        }
      }
      return memo;
    },
    {}
  );

const createErrorMessagesAndAutofixes = (
  field: ControllerRenderProps,
  error: FieldError
) => {
  const itemsSuggestions: React.ReactNode[] = Object.entries(
    groupNestedErrorsIndexesByMessage(error)
  ).map(([message, indexes], index) => (
    <Chip
      color="danger"
      endDecorator={
        <Tooltip size="sm" title="Remove emails">
          <ChipDelete
            onClick={() => {
              field.onChange(
                field.value.filter(
                  (value: unknown, index: number) =>
                    indexes.indexOf(index) === -1
                )
              );
            }}
          />
        </Tooltip>
      }
      key={index}
      size="sm"
      startDecorator={indexes.length}
      variant="plain"
    >
      {message}
    </Chip>
  ));
  let rootSuggestions: string | React.ReactNode = error?.root?.message || null;
  switch (error?.root?.type) {
    // NOTE: This only works if `@hookform/resolvers` was patched to not swallow `ZodIssue`'s extra fields
    case "too_big": {
      const { maximum } = error.root as ZodTooBigIssue;
      if (maximum) {
        rootSuggestions = (
          <Chip
            color="danger"
            endDecorator={
              <Tooltip size="sm" title="Remove emails">
                <ChipDelete
                  onClick={() => {
                    field.onChange(field.value.slice(0, Number(maximum)));
                  }}
                />
              </Tooltip>
            }
            size="sm"
            variant="plain"
          >
            {error?.root?.message}
          </Chip>
        );
      }
      break;
    }
    default:
      break;
  }
  return (
    error?.message || (
      <Stack direction="row" spacing={1}>
        {itemsSuggestions}
        {rootSuggestions}
      </Stack>
    )
  );
};

const RolesAndParticipants: React.FC = memo(() => {
  const { data: availablePublishersQueryData } = useAvailablePublishersQuery();
  // eslint-disable-next-line no-console
  console.log({ availablePublishersQueryData });
  const availablePublishers = useMemo(
    () =>
      availablePublishersQueryData?.availablePublishers.nodes.filter(
        (publisher) => publisher.allowMeasurements
      ) || [],
    [availablePublishersQueryData]
  );
  const normalizedArray: { name: string; emails: string[] }[] = useMemo(() => {
    const participants = availablePublishers.map((publisher) => {
      const { publisherName, publisherParticipants } = publisher;
      return {
        emails: publisherParticipants ?? [],
        name: publisherName,
      };
    });
    return uniqBy(participants, "name");
  }, [availablePublishers]);
  const { control } = useFormContext();
  return (
    <Stack>
      <Typography level="title-md">2. Roles and participants</Typography>
      <Alert>
        All participants need to have a Decentriq account. Invite external
        participants via the Admin portal.
      </Alert>
      <Controller
        control={control}
        name="publishers"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel sx={{ gap: 0.5 }}>
                <FontAwesomeIcon fixedWidth={true} icon={faNewspaper} />
                Publisher(s)
              </FormLabel>
              <Autocomplete
                filterSelectedOptions={true}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => {
                  return option.name === value.name;
                }}
                multiple={true}
                options={normalizedArray}
                sx={{ width: "100%" }}
                {...field}
                onChange={(event, value) => field.onChange(value)}
              />
              <FormHelperText>{error?.message}</FormHelperText>
            </FormControl>
          );
        }}
      />
      <Controller
        control={control}
        name="advertiserUserEmails"
        render={({ field, fieldState }) => {
          const { error } = fieldState;
          return (
            <FormControl
              disabled={field.disabled}
              error={Boolean(error)}
              required={true}
            >
              <FormLabel sx={{ gap: 0.5 }}>
                <FontAwesomeIcon fixedWidth={true} icon={faBullhorn} />
                Advertiser(s)
              </FormLabel>
              <DqParticipantsEditor
                disabled={field.disabled}
                error={error}
                onChange={(event, newValue) => field.onChange(newValue)}
                options={[]}
                placeholder={`e.g. "advertiser@advertiser.com"`}
                value={field.value}
              />
              <FormHelperText>
                {error
                  ? createErrorMessagesAndAutofixes(field, error)
                  : "Type in user email and press Enter"}
              </FormHelperText>
            </FormControl>
          );
        }}
      />
    </Stack>
  );
});
RolesAndParticipants.displayName = "RolesAndParticipants";

export default memo(RolesAndParticipants);
