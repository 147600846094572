import { type Session } from "@decentriq/core";
import { useQuery, type UseQueryResult } from "@tanstack/react-query";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useMediaDataRoomRequest } from "features/mediaDataRoom/hooks";
import { type PublisherDatasetsHashes } from "features/mediaDataRoom/models";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";

interface DatasetsData {
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  hasRequiredData: boolean;
  hasAdvertiserData: boolean;
  hasPublisherData: boolean;
}

export type DatasetsHookResult = Omit<
  UseQueryResult<DatasetsData, Error>,
  "data"
> & {
  data: DatasetsData;
};

interface DatasetsHookPayload {
  queryKeyPrefix: string[];
  dataRoomId: string;
  driverAttestationHash: string;
  session: Session | undefined;
}

const useDatasets = ({
  dataRoomId,
  driverAttestationHash,
  queryKeyPrefix,
  session,
}: DatasetsHookPayload): DatasetsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const [fetchPublishedDatasets] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "retrievePublishedDatasets",
    requestCreator: useCallback(
      (dataRoomIdHex: string) => ({ dataRoomIdHex }),
      []
    ),
  });
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const queryResult = useQuery({
    enabled: Boolean(session),
    queryFn: async (): Promise<DatasetsData> => {
      const {
        audiencesDatasetHashHex: advertiserDatasetHash = null,
        demographicsDatasetHashHex: demographicsDatasetHash = null,
        embeddingsDatasetHashHex: embeddingsDatasetHash = null,
        segmentsDatasetHashHex: segmentsDatasetHash = null,
        matchingDatasetHashHex: matchingDatasetHash = null,
      } = await fetchPublishedDatasets({ options: { session } });
      const hasAdvertiserData = Boolean(advertiserDatasetHash);
      const hasPublisherData = Boolean(matchingDatasetHash);
      const hasRequiredData = hasAdvertiserData && hasPublisherData;
      return {
        advertiserDatasetHash,
        hasAdvertiserData,
        hasPublisherData,
        hasRequiredData,
        publisherDatasetsHashes: {
          demographicsDatasetHash,
          embeddingsDatasetHash,
          matchingDatasetHash,
          segmentsDatasetHash,
        },
      };
    },
    queryKey: [
      ...queryKeyPrefix,
      "published-datasets",
      dataRoomId,
      driverAttestationHash,
    ],
  });
  useEffect(() => {
    if (queryResult.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          queryResult.error,
          `Unable to fetch datasets`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, queryResult.error]);
  return {
    ...queryResult,
    data: queryResult.data ?? {
      advertiserDatasetHash: null,
      hasAdvertiserData: false,
      hasPublisherData: false,
      hasRequiredData: false,
      publisherDatasetsHashes: {
        demographicsDatasetHash: null,
        embeddingsDatasetHash: null,
        matchingDatasetHash: null,
        segmentsDatasetHash: null,
      },
    },
  };
};

export default useDatasets;
