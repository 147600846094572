import { Box, CircularProgress, Stack } from "@mui/joy";
import { useMediaDataRoom } from "features/mediaDataRoom";
import {
  AdvertiserDataNode,
  MediaDataroomDataTabOverlap,
  PublisherDataNode,
} from "features/mediaDataRoom/components";
import { useMediaDataRoomInsightsData } from "features/mediaDataRoom/contexts";

const MediaDataRoomData: React.FC = () => {
  const {
    datasets: { isLoading: datasetsLoading },
  } = useMediaDataRoomInsightsData();
  const {
    supportedFeatures: { hideAbsoluteValues = false },
  } = useMediaDataRoom();

  if (datasetsLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  return (
    <>
      <Stack>
        <AdvertiserDataNode />
        <PublisherDataNode />
      </Stack>
      {!hideAbsoluteValues && <MediaDataroomDataTabOverlap />}
    </>
  );
};

MediaDataRoomData.displayName = "MediaDataRoomData";

export default MediaDataRoomData;
