import { z } from "zod";
import { CollaborationTypes } from "models";
import { RawMatchingID } from "models/dataRoom/matchingId";

export const DOMAIN_REGEXP: RegExp =
  /^[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]{2,24}){1,3}$/;

const commonFields = {
  dataRoomTypes: z
    .array(z.nativeEnum(CollaborationTypes))
    .min(1, "At least one collaboration type is required"),
  matchingId: z.nativeEnum(RawMatchingID, {
    message: "Matching ID is required",
  }),
  name: z.string().min(1, "Name is required"),
  participants: z
    .array(z.string().email("Email is invalid"))
    .min(1, "At least one participant is required"),
};

export const schema = z.discriminatedUnion("matchAnyDomain", [
  z.object({
    advertiserDomains: z.tuple([]),
    matchAnyDomain: z.literal(true),
    ...commonFields,
  }),
  z.object({
    advertiserDomains: z
      .array(z.string().regex(DOMAIN_REGEXP, "Domain is invalid"))
      .min(1, "At least one domain is required"),
    matchAnyDomain: z.literal(false),
    ...commonFields,
  }),
]);

export type SchemaType = z.infer<typeof schema>;

export const defaultValues = {
  advertiserDomains: [],
  dataRoomTypes: [],
  matchAnyDomain: false as false,
  matchingId: undefined,
  name: "",
  participants: [],
};

export const dataPartnerDcrCollaborationTypes = [
  CollaborationTypes.Insights,
  CollaborationTypes.Remarketing,
  CollaborationTypes.Lookalike,
  CollaborationTypes.RuleBasedAudiences,
];
