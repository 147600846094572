import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type MediaDataRoomJobHookResult,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import {
  type OverlapSegment,
  type OverlapSegmentsCollection,
  type PublisherDatasetsHashes,
} from "features/mediaDataRoom/models";
import { datasetsCacheKeyCreator } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type ModelledSegmentsCollection } from "wrappers/ApolloWrapper/resolvers/LookalikeMediaMutations";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

const filterNullableOverlapInsightsRows = <
  T extends OverlapSegmentsCollection | ModelledSegmentsCollection,
>(
  lookalikeMediaOverlapInsights: T
): T["audiences"] => {
  const overlapInsights = (lookalikeMediaOverlapInsights?.audiences || []).map(
    ({ aggregations = [], ...restAudience }) => ({
      ...restAudience,
      aggregations: aggregations.map(
        ({ columns = [], rows = [], ...restAggregation }) => {
          const affinityRatioDataIndex = columns.indexOf("affinity_ratio");
          const filteredRows = rows.filter(
            (row) =>
              ![null, undefined, "N/A"].includes(row[affinityRatioDataIndex])
          );
          return {
            ...restAggregation,
            columns,
            rows: filteredRows,
          };
        }
      ),
    })
  );
  return overlapInsights || [];
};

interface InsightsHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  session?: Session;
  skip: boolean;
}

interface InsightsData {
  insights: OverlapSegment[];
}

export type InsightsHookResult = MediaDataRoomJobHookResult<InsightsData>;

const useInsights = ({
  dataRoomId,
  driverAttestationHash,
  advertiserDatasetHash,
  publisherDatasetsHashes,
  session,
  skip,
}: InsightsHookPayload): InsightsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const cacheKey = useMemo(() => {
    const cacheObj = datasetsCacheKeyCreator(
      advertiserDatasetHash,
      publisherDatasetsHashes
    );
    if (!cacheObj) {
      return null;
    }
    return computeCacheKeyString(cacheObj);
  }, [advertiserDatasetHash, publisherDatasetsHashes]);
  const transform = useCallback(async (zip: JSZip): Promise<InsightsData> => {
    const overlapInsightsSegmentsFile = zip.file("segments.json");
    if (overlapInsightsSegmentsFile === null) {
      throw new Error("segments.json not found in zip");
    }
    const overlapInsightsSegments: OverlapSegmentsCollection = JSON.parse(
      await overlapInsightsSegmentsFile.async("string")
    );
    const insights = filterNullableOverlapInsightsRows(overlapInsightsSegments);

    return {
      insights,
    };
  }, []);
  const computeJob = useMediaDataRoomJob({
    cacheKey,
    dataRoomId,
    driverAttestationHash,
    key: "computeInsights",
    queryKeyPrefix: ["ab", "computeInsights"],
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });

  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch insights`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);

  return computeJob;
};

export default useInsights;
