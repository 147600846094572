import { DqCard, DqCardContent } from "@decentriq/components";
import { faInfo } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Stack, Typography } from "@mui/joy";
import { useMount } from "ahooks";
import { memo } from "react";
import { useMediaDataRoom } from "features/mediaDataRoom";
import {
  EstimatedAudienceSize,
  QualityScore,
  ReachTradeoff,
} from "features/mediaDataRoom/components";
import { useLookalikeAudienceStatistics } from "features/mediaDataRoom/hooks";
import {
  type Audience,
  audienceTypePresentationMap,
} from "features/mediaDataRoom/models";
import { useAudiences } from "features/mediaDataRoom/wrappers";
import AudienceCreationDate from "../AudienceCreationDate/AudienceCreationDate";
import AudienceStatusLabel from "../AudienceStatusLabel/AudienceStatusLabel";
import AudienceDrawerRuleBasedDetails from "./AudienceDrawerRuleBasedDetails";

type AudienceDrawerDetailsProps = {
  seedAudienceName: string | null;
} & Audience;

const AudienceDrawerDetails: React.FC<AudienceDrawerDetailsProps> = (
  audience
) => {
  const { id, created_at, kind, status, name, reach, seedAudienceName } =
    audience;
  const {
    supportedFeatures: { hideAbsoluteValues },
    isPublisher,
  } = useMediaDataRoom();
  const {
    audiences,
    audienceSizes: { enableSizeEstimationForAudience, audienceSizes },
  } = useAudiences();

  const {
    audienceQualityScore,
    computeState: { loading: isLookalikeAudienceStatisticsLoading },
  } = useLookalikeAudienceStatistics({
    audienceId: id,
    audienceReach: reach as number,
    audiences: audiences.computeResults || [],
    skip: kind !== "lookalike",
  });

  useMount(() => {
    if (kind !== "advertiser") {
      enableSizeEstimationForAudience(id);
    }
  });
  return (
    <Stack gap={1}>
      <DqCard>
        <DqCardContent sx={{ alignItems: "flex-start" }}>
          <Stack spacing={0.5}>
            <Typography
              level="body-sm"
              startDecorator={<strong>Type:</strong>}
              textColor="inherit"
            >
              {audienceTypePresentationMap.get(kind) || "N/A"}
            </Typography>
            <Typography
              level="body-sm"
              startDecorator={<strong>Creation date:</strong>}
              textColor="inherit"
            >
              <AudienceCreationDate createdAt={created_at} />
            </Typography>
            {kind !== "advertiser" && seedAudienceName && (
              <Typography
                level="body-sm"
                startDecorator={
                  <strong>Name of the audience used as a seed:</strong>
                }
                textColor="inherit"
              >
                {seedAudienceName}
              </Typography>
            )}
            {!isPublisher && (
              <Typography
                level="body-sm"
                startDecorator={<strong>Status:</strong>}
                textColor="inherit"
              >
                <AudienceStatusLabel id={id} status={status} />
              </Typography>
            )}
          </Stack>
        </DqCardContent>
      </DqCard>
      {kind === "rulebased" && <AudienceDrawerRuleBasedDetails {...audience} />}
      {!hideAbsoluteValues && (
        <EstimatedAudienceSize
          estimatedAudienceSize={
            kind === "advertiser"
              ? audience.audience_size
              : audienceSizes[id]?.audienceSize
          }
          loading={audienceSizes[id]?.loading}
        />
      )}
      {kind === "lookalike" && (
        <>
          <ReachTradeoff reach={reach} />
          <QualityScore
            loading={isLookalikeAudienceStatisticsLoading}
            qualityScore={audienceQualityScore}
          />
        </>
      )}
      <Alert
        color="secondary"
        startDecorator={<FontAwesomeIcon fixedWidth={true} icon={faInfo} />}
        variant="soft"
      >
        {kind === "advertiser"
          ? `This audience has been automatically generated by matching all users from ${name} with the publisher audience.`
          : "You can't edit this audience directly because it has already been finalized"}
      </Alert>
    </Stack>
  );
};

AudienceDrawerDetails.displayName = "AudienceDrawerDetails";

export default memo(AudienceDrawerDetails);
