import {
  faEllipsisV,
  faFileExport,
  faTrash,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Dropdown,
  IconButton,
  ListItemDecorator,
  Menu,
  MenuButton,
  MenuItem,
} from "@mui/joy";
import React, { memo, useCallback } from "react";
import { useAdvertiserAudiences } from "features/mediaDataRoom/contexts";
import { type Audience } from "features/mediaDataRoom/models";
import DeleteAudienceButton from "../DeleteAudienceButton/DeleteAudienceButton";

interface ActionsMenuProps {
  audience: Audience;
}

const ActionsMenu: React.FC<ActionsMenuProps> = memo(({ audience }) => {
  const { publishAudience, isPublishingAudience } = useAdvertiserAudiences();
  const publish = useCallback(
    async () => publishAudience(audience),
    [publishAudience, audience]
  );
  return (
    <>
      <Dropdown>
        <MenuButton
          endDecorator={null}
          slotProps={{
            root: { color: "neutral", variant: "outlined" },
          }}
          slots={{ root: IconButton }}
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </MenuButton>
        <Menu keepMounted={true} placement="bottom-end">
          {audience.status !== "published" && (
            <MenuItem disabled={isPublishingAudience} onClick={publish}>
              <ListItemDecorator>
                <FontAwesomeIcon icon={faFileExport} />
              </ListItemDecorator>
              Grant publisher access
            </MenuItem>
          )}
          <DeleteAudienceButton
            audience={audience}
            renderTrigger={(openDeleteAudienceDialog) => (
              <MenuItem onClick={openDeleteAudienceDialog}>
                <ListItemDecorator>
                  <FontAwesomeIcon icon={faTrash} />
                </ListItemDecorator>
                Delete audience
              </MenuItem>
            )}
          />
        </Menu>
      </Dropdown>
    </>
  );
});

ActionsMenu.displayName = "ActionsMenu";

export default ActionsMenu;
