import { DqAudienceRulesBuilder } from "@decentriq/components";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, Divider, Grid, Stack, Typography } from "@mui/joy";
import { memo, useCallback } from "react";
import { EstimatedAudienceSize } from "features/mediaDataRoom/components";
import {
  AdvertiserAudienceGeneratorHeader,
  AdvertiserAudienceGeneratorStep,
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
  useAdvertiserAudienceGenerator,
} from "features/mediaDataRoom/containers/AdvertiserAudienceGenerator";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import { useRulesBasedAdvertiserAudienceGenerator } from "../../contexts/RulesBasedAdvertiserAudienceGeneratorContext";

const RulesBasedAdvertiserAudienceGeneratorConfigurationStep = memo(() => {
  const { supportedFeatures } = useMediaDataRoom();
  const { handleBackStep, handleNextStep, isFirstStep, cancelGeneration } =
    useAdvertiserAudienceGenerator();
  const {
    onRulesTreeChanged,
    seedOptions,
    rulesTree,
    estimatedAudienceSize,
    estimateAudienceSize,
    isEstimatingAudienceSize,
    validateRulesTree,
  } = useRulesBasedAdvertiserAudienceGenerator();
  const shouldCancelGenerationOnBack = isFirstStep(
    AdvertiserAudienceGeneratorStep.CONFIGURATION
  );
  const handleEstimateAudienceSize = useCallback(() => {
    const isValid = validateRulesTree();
    if (!isValid) {
      return;
    }
    estimateAudienceSize();
  }, [estimateAudienceSize, validateRulesTree]);
  const handleProceedNextStep = useCallback(() => {
    const isValid = validateRulesTree();
    if (!isValid) {
      return;
    }
    handleNextStep();
  }, [handleNextStep, validateRulesTree]);
  return (
    <>
      <Grid container={true} sx={{ height: "100%" }}>
        <Grid xs={7}>
          <Box mb={3}>
            <AdvertiserAudienceGeneratorHeader />
          </Box>
          <AudienceGeneratorStepSectionTitle>
            1. Rule-based audience setup
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Set up your audience by defining specific criteria and parameters
            for precise targeting.
          </AudienceGeneratorStepSectionDescription>
          <DqAudienceRulesBuilder
            config={seedOptions.computeResults ?? []}
            defaultValue={rulesTree}
            onTreeChange={onRulesTreeChanged}
          />
        </Grid>
        {!supportedFeatures.hideAbsoluteValues && (
          <>
            <Grid display="flex" justifyContent="center" m={-2} xs={1}>
              <Divider orientation="vertical" />
            </Grid>
            <Grid xs={4}>
              <Divider orientation="vertical" />
              <Typography
                fontWeight={600}
                level="title-md"
                mb={3}
                textAlign="center"
              >
                Audience size
              </Typography>
              <Stack spacing={2}>
                <Alert
                  color="neutral"
                  startDecorator={<FontAwesomeIcon icon={faInfoCircle} />}
                  variant="soft"
                >
                  The estimated audience size calculation may take some time.
                  Changes to attributes will not trigger automatic
                  recalculations. Please manually update to see the latest
                  estimates.
                </Alert>
                <EstimatedAudienceSize
                  estimatedAudienceSize={estimatedAudienceSize}
                  loading={isEstimatingAudienceSize}
                />
                <Button
                  color="primary"
                  disabled={isEstimatingAudienceSize}
                  onClick={handleEstimateAudienceSize}
                  variant="outlined"
                >
                  {estimatedAudienceSize
                    ? "Recalculate audience estimate"
                    : "Calculate audience estimate"}
                </Button>
              </Stack>
            </Grid>
          </>
        )}
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button
          onClick={
            shouldCancelGenerationOnBack ? cancelGeneration : handleBackStep
          }
          sx={{ mr: 1 }}
        >
          {shouldCancelGenerationOnBack ? "Cancel" : "Back"}
        </Button>
        <Button color="primary" onClick={handleProceedNextStep} variant="solid">
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

RulesBasedAdvertiserAudienceGeneratorConfigurationStep.displayName =
  "RulesBasedAdvertiserAudienceGeneratorConfigurationStep";

export default RulesBasedAdvertiserAudienceGeneratorConfigurationStep;
