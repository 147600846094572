import { faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress, Typography } from "@mui/joy";
import { memo, type MouseEventHandler, useCallback } from "react";
import { useMediaDataRoomInsightsData } from "features/mediaDataRoom/contexts";

const AdvertiserDataReportLabel: React.FC<{}> = () => {
  const { advertiserDataReport } = useMediaDataRoomInsightsData();
  const entryLabel = useCallback(
    (num: number) => (num === 1 ? "entry" : "entries"),
    []
  );

  const retry: MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      event.stopPropagation();
      advertiserDataReport.retry();
    },
    [advertiserDataReport]
  );

  if (advertiserDataReport.error) {
    return (
      <>
        <FontAwesomeIcon color="red" icon={faTriangleExclamation} />
        <Typography sx={{ color: "red", fontWeight: "normal", ml: 1 }}>
          Failed to fetch data report{" "}
        </Typography>
        <Button color="secondary" onClick={retry}>
          Retry
        </Button>
      </>
    );
  }
  if (advertiserDataReport.status !== "COMPLETED") {
    return (
      <>
        Checking for duplicates in the data...
        <CircularProgress
          sx={{ "--CircularProgress-size": "12px", marginLeft: "6px" }}
        />
      </>
    );
  }
  if (!advertiserDataReport.computeResults) {
    return <>Data report is missing</>;
  }
  const { numberOfDeduplicatedRows, numberOfIngestedRows } =
    advertiserDataReport.computeResults;
  if (!numberOfDeduplicatedRows) {
    return <>No duplicates found in the data</>;
  }
  return (
    <>{`Dropped ${numberOfDeduplicatedRows} duplicate ${entryLabel(
      numberOfDeduplicatedRows
    )}. There are ${numberOfIngestedRows} remaining ${entryLabel(
      numberOfIngestedRows
    )}.`}</>
  );
};

export default memo(AdvertiserDataReportLabel);
