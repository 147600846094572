import { DqCard, DqCardContent } from "@decentriq/components";
import { faFloppyDisk, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { LoadingButton } from "@mui/lab";
import { memo } from "react";
import {
  EstimatedAudienceSize,
  QualityScore,
  ReachTradeoff,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import {
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
  useAdvertiserAudienceGenerator,
} from "features/mediaDataRoom/containers/AdvertiserAudienceGenerator";
import { useLookalikeAdvertiserAudienceGenerator } from "../..";

const LookalikeAdvertiserAudienceGeneratorSummaryStep = memo(() => {
  const {
    generateLookalikeAudienceLoading,
    generateLookalikeAudience,
    selectedAudienceStatistics,
    selectedSeedAudience,
    audienceName,
    setAudienceName,
    reach,
    enableExtendedLookalikeStatistics,
  } = useLookalikeAdvertiserAudienceGenerator();
  const {
    estimatedAudienceSize,
    audienceQualityScore,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  const {
    supportedFeatures: { hideAbsoluteValues },
  } = useMediaDataRoom();
  const { handleBackStep } = useAdvertiserAudienceGenerator();

  return (
    <>
      <Grid container={true} md={5} xs={12}>
        <Grid mb={2} xs={12}>
          <AudienceGeneratorStepSectionTitle>
            1. Name your audience
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Provide a name that reflects your audience's purpose or focus.
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid mb={2} xs={12}>
          <FormControl sx={{ width: "100%" }}>
            <FormLabel>Audience name</FormLabel>
            <Input
              autoComplete="off"
              onChange={(event) => setAudienceName(event.target.value)}
              placeholder="e.g. 'New audience'"
              value={audienceName}
            />
          </FormControl>
        </Grid>
        {!hideAbsoluteValues && (
          <Grid mb={2} xs={12}>
            <AudienceGeneratorStepSectionTitle>
              2. Estimated audience size
            </AudienceGeneratorStepSectionTitle>
            <AudienceGeneratorStepSectionDescription>
              This section displays the estimated size of your new audience.
            </AudienceGeneratorStepSectionDescription>
          </Grid>
        )}
        {isStatisticsComputationLoading && (
          <Grid mb={1} xs={12}>
            <Alert
              color="neutral"
              startDecorator={<FontAwesomeIcon icon={faInfoCircle} />}
              variant="soft"
            >
              <Stack spacing={0}>
                <Typography level="title-md">Audience is computing</Typography>
                <Typography level="body-sm">
                  This may take up to an hour. You can still save the audience
                  during this time, but results won't be immediate.
                </Typography>
              </Stack>
            </Alert>
          </Grid>
        )}
        {!hideAbsoluteValues && (
          <Grid mb={1} xs={12}>
            <EstimatedAudienceSize
              estimatedAudienceSize={estimatedAudienceSize}
              loading={isStatisticsComputationLoading}
            />
          </Grid>
        )}
        <Grid mb={1} xs={12}>
          <ReachTradeoff reach={reach} />
        </Grid>
        {enableExtendedLookalikeStatistics && (
          <Grid xs={12}>
            <QualityScore
              loading={isStatisticsComputationLoading}
              qualityScore={audienceQualityScore}
            />
          </Grid>
        )}
        <Grid mb={2} mt={2} xs={12}>
          <AudienceGeneratorStepSectionTitle>
            3. Audience summary
          </AudienceGeneratorStepSectionTitle>
          <AudienceGeneratorStepSectionDescription>
            Details of your audience configuration below:
          </AudienceGeneratorStepSectionDescription>
        </Grid>
        <Grid xs={12}>
          <DqCard>
            <DqCardContent sx={{ alignItems: "flex-start" }}>
              <Stack spacing={0.5}>
                <Typography level="body-sm" textColor="inherit">
                  <strong>Type: </strong>
                  Lookalike
                </Typography>
                <Typography level="body-sm" textColor="inherit">
                  <strong>Name of the audience: </strong>
                  {audienceName}
                </Typography>
                <Typography level="body-sm" textColor="inherit">
                  <strong>Name of the audience used as seed: </strong>
                  {selectedSeedAudience?.name || "N/A"}
                </Typography>
              </Stack>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button onClick={handleBackStep} sx={{ mr: 1 }}>
          Back
        </Button>
        <LoadingButton
          color="primary"
          loading={generateLookalikeAudienceLoading}
          loadingPosition="start"
          onClick={generateLookalikeAudience}
          startIcon={<FontAwesomeIcon icon={faFloppyDisk} />}
          sx={{ height: "fit-content" }}
          variant="contained"
        >
          Save audience
        </LoadingButton>
      </AudienceGeneratorStepActionsWrapper>
    </>
  );
});

LookalikeAdvertiserAudienceGeneratorSummaryStep.displayName =
  "LookalikeAdvertiserAudienceGeneratorSummaryStep";

export default LookalikeAdvertiserAudienceGeneratorSummaryStep;
