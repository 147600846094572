import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type MediaDataRoomJobHookResult,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

interface AdvertiserDataReportHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  advertiserDatasetHash: string | null;
  session?: Session;
  skip: boolean;
}

const useAdvertiserDataReport = ({
  session,
  dataRoomId,
  driverAttestationHash,
  advertiserDatasetHash,
  skip,
}: AdvertiserDataReportHookPayload): MediaDataRoomJobHookResult<{
  numberOfDeduplicatedRows: number;
  numberOfIngestedRows: number;
}> => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const cacheKey = useMemo<string | null>(
    () =>
      advertiserDatasetHash
        ? computeCacheKeyString({
            advertiserDatasetHash,
            dataRoomId,
          })
        : null,
    [advertiserDatasetHash, dataRoomId]
  );
  const transform = useCallback(async (zip: JSZip) => {
    const reportFile = zip.file("report.json");
    if (reportFile === null) {
      throw new Error("report.json not found in zip");
    }
    const report:
      | { num_ingested_rows: number; num_deduplicated_rows: number }
      | undefined = JSON.parse(await reportFile.async("string"))["audiences"];
    if (
      !report ||
      report.num_deduplicated_rows === undefined ||
      report.num_ingested_rows === undefined
    ) {
      throw new Error("Advertiser data report is missing");
    }
    return {
      numberOfDeduplicatedRows: report.num_deduplicated_rows,
      numberOfIngestedRows: report.num_ingested_rows,
    };
  }, []);
  const computeJob = useMediaDataRoomJob({
    cacheKey,
    dataRoomId,
    driverAttestationHash,
    key: "ingestAudiencesReport",
    queryKeyPrefix: ["ab-dcr-advertiser-data-report"],
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });
  useEffect(() => {
    const error = computeJob.error;
    if (error) {
      const key = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          error,
          `Cannot fetch Advertiser data report`
        )
      );
      setErrorSnackbarId(key);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [closeSnackbar, computeJob.error, enqueueSnackbar, setErrorSnackbarId]);

  return computeJob;
};

export default useAdvertiserDataReport;
