import {
  ColumnDataType,
  type MatchingColumnFormat,
  TableColumnFormatType,
  type TableColumnHashingAlgorithm,
} from "@decentriq/graphql/dist/types";
import { type parameter_payloads as ddcParameterPayloads } from "ddc";
import { type DataRoomTableColumn } from "models";

export enum MediaDataRoomUserRole {
  Publisher = "publisher",
  Advertiser = "advertiser",
  Observer = "observer",
  Agency = "agency",
  DataPartner = "dataPartner",
}

export enum MediaDataRoomTab {
  data = "data",
  insights = "insights",
  activation = "activation",
  auditLog = "auditLog",
}

export enum MediaDataRoomActivationTab {
  creation = "creation",
}

export const mediaDataRoomTabs = [
  MediaDataRoomTab.data,
  MediaDataRoomTab.insights,
  MediaDataRoomTab.activation,
  MediaDataRoomTab.auditLog,
];

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

interface SegmentAggregation {
  column: string;
  possible_values: string[];
}

export interface SegmentAggregationsCollection {
  aggregation: SegmentAggregation[];
  audience_type: string;
  columns: string[];
  id: string;
  rows: any[][];
}

export interface OverlapSegment {
  audience_type: string;
  aggregations: SegmentAggregationsCollection[];
}

export interface OverlapSegmentsCollection {
  audiences: OverlapSegment[];
}

export interface PublisherDatasetsHashes {
  matchingDatasetHash: string | null;
  segmentsDatasetHash: string | null;
  demographicsDatasetHash: string | null;
  embeddingsDatasetHash: string | null;
}

export enum RawSupportedFeatures {
  ENABLE_MODEL_PERFORMANCE_EVALUATION = "ENABLE_MODEL_PERFORMANCE_EVALUATION",
  ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES = "ENABLE_HIDE_ABSOLUTE_AUDIENCE_SIZES",
  ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD = "ENABLE_ADVERTISER_AUDIENCE_DOWNLOAD",
}

export const getAdvertiserAudienceColumns: (
  matchingIdFormat: TableColumnFormatType,
  hashMatchingIdWith: TableColumnHashingAlgorithm | null
) => DataRoomTableColumn[] = (matchingIdFormat, hashMatchingIdWith) => [
  {
    formatType: matchingIdFormat,
    hashWith: hashMatchingIdWith ?? undefined,
    id: "matchingId",
    name: "matchingId",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
  {
    default: "All customers",
    formatType: TableColumnFormatType.String,
    id: "audienceType",
    name: "audienceType",
    nullable: false,
    primitiveType: ColumnDataType.Text,
    tableNodeId: "audience_leaf",
  },
];

// TODO: Reusing the same type from ddc even though it has snake_case keys as in the future they wii be converted to camelCase
export type Audience = ddcParameterPayloads.Audience;
export type AdvertiserAudience = Extract<Audience, { kind: "advertiser" }>;
export type LookalikeAudience = Extract<Audience, { kind: "lookalike" }>;
export type RuleBasedAudience = Extract<Audience, { kind: "rulebased" }>;

export interface AudiencesFileStructure {
  advertiser_manifest_hash: string;
  matching_manifest_hash: string;
  segments_manifest_hash?: string;
  embeddings_manifest_hash?: string;
  demographics_manifest_hash?: string;
  audiences: ddcParameterPayloads.Audience[];
}

export interface LookalikeAudienceStatisticsRaw {
  addressable_audience_size: number;
  filtered_audiences: FilteredLookalikeAudience[];
  quality: LookalikeAudienceQuality;
  seed_audience_size: number;
}

export interface FilteredLookalikeAudience {
  audience_type: string;
  reach: number;
  size: number;
  quality: FilteredLookalikeAudienceQuality;
}

export interface FilteredLookalikeAudienceQuality {
  roc_curve: FilteredLookalikeAudienceROCCurve;
}

export interface FilteredLookalikeAudienceROCCurve {
  fpr: number;
  tpr: number;
  score: number;
}

export interface LookalikeAudienceQuality {
  roc_curve: LookalikeAudienceROCCurve;
}

export interface LookalikeAudienceROCCurve {
  auc: number;
  fpr: [number, number];
  reach: [number, number];
  test_set_size?: number;
  test_set_positive_examples_size?: number;
  tpr: [number, number];
}

export type AudienceKind = Pick<Audience, "kind">["kind"];

export const audienceTypePresentationMap = new Map<AudienceKind, string>([
  ["lookalike", "AI Lookalike"],
  ["advertiser", "Remarketing"],
  ["rulebased", "Rule-based"],
]);

export const audienceStatusPresentationMap = new Map<AudienceStatus, string>([
  ["published", "Available to publisher"],
  ["published_as_intermediate", "Ready"],
  ["ready", "Ready"],
]);

export interface MediaInsightsAudienceCacheKey {
  audienceKind: AudienceKind;
  dataRoomId: string;
  advertiserDatasetHash: string;
  publisherUsersDatasetHash: string;
  publisherSegmentsDatasetHash?: string | null;
  publisherDemographicsDatasetHash?: string | null;
  publisherEmbeddingsDatasetHash?: string | null;
  audiences: Audience[];
  audienceId: string;
  reach?: number;
}

export interface InsightsBasicViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
}

export interface InsightsComparisonViewFormValues {
  aggregation: string;
  audienceType: string;
}

export interface InsightsDetailedViewFormValues {
  audienceType: string;
  genderChartValueKey: string;
  // Defines whether selected best or worst affinityRatio values
  dataSortDirection: boolean | "desc" | "asc";
}

export const DEFAULT_PARTICIPANTS_EMAILS = new Map<
  MediaDataRoomUserRole,
  string[]
>([
  [MediaDataRoomUserRole.Publisher, []],
  [MediaDataRoomUserRole.DataPartner, []],
  [MediaDataRoomUserRole.Advertiser, []],
  [MediaDataRoomUserRole.Observer, []],
  [MediaDataRoomUserRole.Agency, []],
]);

export interface PublishMediaDataRoomInput {
  dataPartnerEmails: string[];
  advertiserEmails: string[];
  agencyEmails: string[];
  enableInsights: boolean;
  enableLookalike: boolean;
  enableRemarketing: boolean;
  enableRuleBasedAudiences: boolean;
  hideAbsoluteValuesFromInsights: boolean;
  enableAdvertiserAudienceDownload: boolean;
  observerEmails: string[];
  publisherEmails: string[];
  mainAdvertiserEmail: string;
  mainPublisherEmail: string;
  name: string;
  matchingIdFormat: MatchingColumnFormat;
  matchingIdHashingAlgorithm?: TableColumnHashingAlgorithm;
}

export const entireOverlapKey = "All audiences combined";

export interface AggregationDataModel {
  addressableAudienceSize: number;
  affinityRatio: number;
  age?: string;
  aggregationName?: string;
  countUsersOverlap: number;
  interest?: string;
  gender?: string;
  shareInAddressableAudience: number;
  shareInOverlap: number;
}

export type AudienceFilter = AudienceKind | "all";

export type AudienceStatus = ddcParameterPayloads.Status;
