import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { memo, useCallback } from "react";
import { useAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/AdvertiserAudienceGenerator";
import { useLookalikeAdvertiserAudienceGenerator } from "../../contexts/LookalikeAdvertiserAudienceGeneratorContext";

const ExcludeSeedAudienceInfoDialog: React.FC = () => {
  const {
    excludeSeedAudience,
    isExcludeSeedAudienceInfoDialogVisible,
    closeExcludeSeedAudienceInfoDialog,
  } = useLookalikeAdvertiserAudienceGenerator();
  const { handleNextStep } = useAdvertiserAudienceGenerator();
  const handleContinueClick = useCallback(() => {
    handleNextStep();
    closeExcludeSeedAudienceInfoDialog();
  }, [closeExcludeSeedAudienceInfoDialog, handleNextStep]);
  return (
    <Modal
      onClose={closeExcludeSeedAudienceInfoDialog}
      open={isExcludeSeedAudienceInfoDialogVisible}
    >
      <ModalDialog>
        <DialogContent>
          {excludeSeedAudience ? (
            <span>
              You have selected to <b>exclude</b> the matched seed audience in
              the lookalike audience. This means none of the users used for this
              activation will be ones that have matched, it will be the
              lookalikes “only”. All further audiences in this clean room will
              use the same setting. This is to reduce the risk of you users
              being singled out if you send very similar audiences with them
              included and excluded.
            </span>
          ) : (
            <span>
              You have selected to <b>include</b> the matched seed audience in
              the lookalike audience. This is typically recommended, but all
              further audiences in this clean room will use the same setting.
              This is to reduce the risk of you users being singled out if you
              send very similar audiences with them included and excluded.
            </span>
          )}
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeExcludeSeedAudienceInfoDialog}>Cancel</Button>
          <Button color="primary" onClick={handleContinueClick} variant="solid">
            Continue
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};

ExcludeSeedAudienceInfoDialog.displayName = "ExcludeSeedAudienceInfoDialog";

export default memo(ExcludeSeedAudienceInfoDialog);
