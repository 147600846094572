import { type Session } from "@decentriq/core";
import type JSZip from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  type MediaDataRoomJobHookResult,
  useMediaDataRoomJob,
} from "features/mediaDataRoom/hooks";
import { type PublisherDatasetsHashes } from "features/mediaDataRoom/models";
import { datasetsCacheKeyCreator } from "features/mediaDataRoom/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

interface OverlapStatistic {
  audience_type: string;
  advertiser_size: number;
  overlap_size: number;
}

interface OverlapStatisticsCollection {
  overlap_statistics: OverlapStatistic[];
  total_publisher: number;
}

interface AudienceOverlapStatisticsRaw {
  advertiserSize: number;
  audienceType: string;
  overlapSize: number;
}

interface OverlapStatisticsHookPayload {
  dataRoomId: string;
  driverAttestationHash: string;
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  session?: Session;
  skip: boolean;
}

export interface OverlapStatisticsData {
  statistics: AudienceOverlapStatisticsRaw[];
  isEmpty: boolean;
  totalPublisherCount: number;
  withNoiseCalculation: boolean;
}

export type OverlapStatisticsHookResult =
  MediaDataRoomJobHookResult<OverlapStatisticsData>;

const useOverlapStatistics = ({
  dataRoomId,
  driverAttestationHash,
  advertiserDatasetHash,
  publisherDatasetsHashes,
  session,
  skip,
}: OverlapStatisticsHookPayload): OverlapStatisticsHookResult => {
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const cacheKey = useMemo(() => {
    const cacheObj = datasetsCacheKeyCreator(
      advertiserDatasetHash,
      publisherDatasetsHashes
    );
    if (!cacheObj) {
      return null;
    }
    return computeCacheKeyString(cacheObj);
  }, [advertiserDatasetHash, publisherDatasetsHashes]);
  const transform = useCallback(
    async (zip: JSZip): Promise<OverlapStatisticsData> => {
      const overlapInsightsStatisticsFile = zip.file("overlap.json");
      if (overlapInsightsStatisticsFile === null) {
        throw new Error("overlap.json not found in zip");
      }
      const overlapInsightsStatistics: OverlapStatisticsCollection = JSON.parse(
        await overlapInsightsStatisticsFile.async("string")
      );
      const formattedStatistics: AudienceOverlapStatisticsRaw[] = (
        overlapInsightsStatistics?.overlap_statistics || []
      ).map((overlap: OverlapStatistic) => ({
        advertiserSize: overlap.advertiser_size,
        audienceType: overlap.audience_type,
        overlapSize: overlap.overlap_size,
      }));
      return {
        isEmpty: formattedStatistics.every(
          ({ overlapSize }) => overlapSize === 0
        ),
        statistics: formattedStatistics,
        totalPublisherCount: overlapInsightsStatistics?.total_publisher,
        withNoiseCalculation: !!overlapInsightsStatistics?.total_publisher,
      };
    },
    []
  );
  const computeJob = useMediaDataRoomJob({
    cacheKey,
    dataRoomId,
    driverAttestationHash,
    key: "computeOverlapStatistics",
    queryKeyPrefix: ["ab", "computeOverlapStatistics"],
    requestCreator: useCallback(
      (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      []
    ),
    session,
    skip,
    transform,
  });
  useEffect(() => {
    if (computeJob.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          computeJob.error,
          `Cannot fetch Overlap statistics`
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [enqueueSnackbar, closeSnackbar, setErrorSnackbarId, computeJob.error]);
  return computeJob;
};

export default useOverlapStatistics;
