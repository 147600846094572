import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Stack, Tooltip, Typography } from "@mui/joy";
import { memo } from "react";
import { useFormContext } from "react-hook-form";
import AttributionItem from "./AttributionItem";

const lookBackWindow = [
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];
const lookBackWindowWithNone = [
  { label: "None", value: 0 },
  { label: "1 Day", value: 1 },
  { label: "7 Days", value: 7 },
  { label: "28 Days", value: 28 },
  { label: "91 Days", value: 91 },
];

const attributions = [
  { label: "Last Click + Viewthrough", value: "last_click" },
  { label: "Even Credit", value: "even_credit" },
  { label: "First and Last", value: "first_last" },
];

const AttributionSelector: React.FC = memo(() => {
  const { watch } = useFormContext();
  const clickLookbackWindow = watch(
    "measurementConfiguration.clickLookbackWindow"
  );
  return (
    <Stack>
      <Typography
        level="title-md"
        sx={{ alignItems: "center", display: "flex", gap: 0.5 }}
      >
        3. Click lookback and attribution
        <Tooltip
          title={
            <div>
              <div>
                The maximum number days a click or view may be before a
                conversion and be attributed. Clicks and views may be set
                separately, but the view window may not be longer than the click
                window.
              </div>
              <div>
                For the click-only attribution set the View Lookback Window to
                “None”.
              </div>
              <div>
                The “Last Click + Viewthrough” Attribution Rule prioritizes
                clicks over views, all other rules treat clicks and views
                equally.
              </div>
            </div>
          }
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>
      </Typography>
      <AttributionItem
        attributionLabel="Click Lookback Window"
        fieldName="measurementConfiguration.clickLookbackWindow"
        options={lookBackWindow}
      />
      <AttributionItem
        attributionLabel="View Lookback Window"
        fieldName="measurementConfiguration.viewLookbackWindow"
        options={lookBackWindowWithNone.map(({ label, value }) => {
          return {
            disabled: !!clickLookbackWindow && clickLookbackWindow < value,
            label,
            value,
          };
        })}
      />
      <AttributionItem
        attributionLabel="Attribution Rule"
        fieldName="measurementConfiguration.attributionRule"
        options={attributions}
      />
    </Stack>
  );
});
AttributionSelector.displayName = "AttributionSelector";

export default AttributionSelector;
