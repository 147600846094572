import {
  AudienceCombinatorOperator,
  AudienceCondition,
  type AudienceFilters,
  AudienceFiltersBooleanOperator,
  type AudienceRulesTree,
} from "@decentriq/components";
import { type parameter_payloads as ddcParameterPayloads } from "ddc";
import {
  type PublisherDatasetsHashes,
  type RuleBasedAudience,
} from "features/mediaDataRoom/models";

export const replaceDecentriqOrgName = (
  orgName: string | undefined,
  replacement: string
): string => (orgName === "Decentriq" || !orgName ? replacement : orgName);

export const datasetsCacheKeyCreator = (
  audiencesDatasetHash: string | null,
  publisherDatasetsHashes: PublisherDatasetsHashes
) => {
  if (!audiencesDatasetHash || !publisherDatasetsHashes?.matchingDatasetHash) {
    return null;
  }
  return {
    audiencesDatasetHash,
    matchingDatasetHash: publisherDatasetsHashes.matchingDatasetHash,
    ...(publisherDatasetsHashes.segmentsDatasetHash
      ? {
          publisherSegmentsDatasetHash:
            publisherDatasetsHashes.segmentsDatasetHash,
        }
      : {}),
    ...(publisherDatasetsHashes.demographicsDatasetHash
      ? {
          publisherDemographicsDatasetHash:
            publisherDatasetsHashes.demographicsDatasetHash,
        }
      : {}),
    ...(publisherDatasetsHashes.embeddingsDatasetHash
      ? {
          publisherEmbeddingsDatasetHash:
            publisherDatasetsHashes.embeddingsDatasetHash,
        }
      : {}),
  };
};

// Transforms data for UI purposes
export const formatBasePropensity = (basePropensity: number): string =>
  `${basePropensity}%`;
export const formatOverlapPropensity = (overlapPropensity: number): string =>
  `${overlapPropensity}%`;
export const formatNetPropensity = (netPropensity: number): string =>
  `${netPropensity}x`;

export const normalizeEmail = (email: string) => email.trim().toLowerCase();

// TODO: this should be removed after checking with Lorenzo that DCR with email case issue is not used anymore
export const isNormalizedEmailIncluded = (
  emails: (string | null | undefined)[],
  currentEmail: string
) =>
  emails.some(
    (email) => normalizeEmail(email || "") === normalizeEmail(currentEmail)
  );

const mapFilterOperatorToAudienceCondition: Record<
  ddcParameterPayloads.FilterOperator,
  AudienceCondition
> = {
  contains_all_of: AudienceCondition.CONTAINS_ALL_OF,
  contains_any_of: AudienceCondition.CONTAINS_ANY_OF,
  contains_none_of: AudienceCondition.CONTAINS_NONE_OF,
  empty: AudienceCondition.EMPTY,
  not_empty: AudienceCondition.NOT_EMPTY,
};

const mapCombineOperatorToAudienceCombinatorOperator: Record<
  ddcParameterPayloads.CombineOperator,
  AudienceCombinatorOperator
> = {
  diff: AudienceCombinatorOperator.DIFF,
  intersect: AudienceCombinatorOperator.INTERSECT,
  union: AudienceCombinatorOperator.UNION,
};

const mapBooleanOpToAudienceFiltersOperator: Record<
  ddcParameterPayloads.BooleanOp,
  AudienceFiltersBooleanOperator
> = {
  and: AudienceFiltersBooleanOperator.AND,
  or: AudienceFiltersBooleanOperator.OR,
};

const mapDdcAudienceFiltersToAudienceFilters = (
  filters: ddcParameterPayloads.AudienceFilters
): AudienceFilters => ({
  filters: filters.filters.map(({ attribute, operator, values }) => ({
    attribute,
    condition: mapFilterOperatorToAudienceCondition[operator],
    values,
  })),
  operator: mapBooleanOpToAudienceFiltersOperator[filters.boolean_op],
});

export const mapRulesBasedAudiencetoAudienceRulesTree = ({
  combine,
  filters,
  name,
  source_ref,
}: RuleBasedAudience): AudienceRulesTree => ({
  combine: combine
    ? combine.map(({ operator, source_ref, filters, name }) => ({
        filters: filters
          ? mapDdcAudienceFiltersToAudienceFilters(filters)
          : undefined,
        name: name as string,
        operator: mapCombineOperatorToAudienceCombinatorOperator[operator],
        source_ref,
      }))
    : undefined,
  filters: filters
    ? mapDdcAudienceFiltersToAudienceFilters(filters)
    : undefined,
  name,
  source_ref,
});

export const formatNumber = (number: number): string =>
  Intl.NumberFormat("de-DE", { notation: "standard" }).format(number);
