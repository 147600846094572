import { faBan, faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Box, Button, CircularProgress, Typography } from "@mui/joy";
import { useCallback, useMemo, useState } from "react";
import {
  type AudienceFilter,
  MediaDataRoomAudiencesTable,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";
import { useAudiences } from "features/mediaDataRoom/wrappers";
import EmptyStateContainer from "../EmptyStateContainer/EmptyStateContainer";
import MediaDataRoomAudiencesToolbar from "./MediaDataRoomAudiencesToolbar";

const MediaDataRoomActivation: React.FC = () => {
  const [filter, setFilter] = useState<AudienceFilter>("all");
  const [searchValue, setSearchValue] = useState("");
  const { isPublisher, isAdvertiser, isDeactivated } = useMediaDataRoom();
  const {
    datasets: { isLoading: isDataRoomDataLoading },
    hasRequiredData,
    isPublisherAudienceBeingUpdated,
  } = useMediaDataRoomInsightsData();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const { audiences } = useAudiences();
  const loading = useMemo(() => {
    return (
      isDataRoomDataLoading ||
      audiences.loading ||
      audiences.status !== "COMPLETED"
    );
  }, [isDataRoomDataLoading, audiences.status, audiences.loading]);
  const isFiltered = useMemo(
    () => filter !== "all" || !!searchValue.trim(),
    [filter, searchValue]
  );
  const filteredAudiences = useMemo(() => {
    return audiences.computeResults?.filter((audience) => {
      const matchesStatus =
        !isPublisher || audience.status !== "published_as_intermediate";
      const matchesFilter = filter === "all" || audience.kind === filter;
      const matchesSearch = audience.name
        .toLowerCase()
        .trim()
        .includes(searchValue.toLowerCase().trim());
      return matchesStatus && matchesFilter && matchesSearch;
    });
  }, [audiences.computeResults, filter, isPublisher, searchValue]);

  const retryComputations = useCallback(async () => {
    setRetryInProgress(true);
    try {
      audiences.retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [audiences, setRetryInProgress]);
  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          Publisher audience is being updated.
        </Typography>
        <Typography level="body-sm">
          {isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."}
        </Typography>
      </EmptyStateContainer>
    );
  }
  if (!isDataRoomDataLoading && !hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }
  if (loading) {
    return (
      <EmptyStateContainer>
        <Typography
          level="body-sm"
          sx={{ alignItems: "center", display: "inline-flex" }}
        >
          <CircularProgress sx={{ "--CircularProgress-size": "14px", mr: 1 }} />
          {isAdvertiser &&
            (audiences.status === "COMPUTING"
              ? "Computing lookalike models"
              : audiences.status === "FETCHING"
                ? "Retrieving audiences..."
                : "")}
          {isPublisher && "Loading audiences..."}
        </Typography>
      </EmptyStateContainer>
    );
  }
  if (audiences.error) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography level="body-sm" mt={1}>
          Activated audiences could not be retrieved
        </Typography>
        {!isDeactivated && (
          <Button
            color="secondary"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
          >
            Retry
          </Button>
        )}
      </EmptyStateContainer>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <MediaDataRoomAudiencesToolbar
        onFilterChange={setFilter}
        onSearchChange={setSearchValue}
        searchValue={searchValue}
        selectedFilter={filter}
      />
      <MediaDataRoomAudiencesTable
        audiences={filteredAudiences || []}
        isFiltered={isFiltered}
      />
    </Box>
  );
};

MediaDataRoomActivation.displayName = "MediaDataRoomActivation";

export default MediaDataRoomActivation;
