import { useApolloClient } from "@apollo/client";
import { CreateDatasetImportDocument } from "@decentriq/graphql/dist/types";
import { Button } from "@mui/joy";
import { useQuery } from "@tanstack/react-query";
import format from "date-fns/format";
import saveAs from "file-saver";
import type JSZip from "jszip";
import snakeCase from "lodash/snakeCase";
import { type SnackbarKey } from "notistack";
import {
  createContext,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import {
  type AudienceSizesHookResult,
  type MediaDataRoomJobHookResult,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
  useAudienceSizes,
  useMediaDataRoomJob,
  useMediaDataRoomLazyJob,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type AudiencesFileStructure,
} from "features/mediaDataRoom/models";
import { datasetsCacheKeyCreator } from "features/mediaDataRoom/utils";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useSafeContext,
} from "hooks";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";
import { useMediaDataRoom } from "../MediaDataRoomContext";
import { useMediaDataRoomInsightsData } from "../MediaDataRoomInsightsDataContext";

export interface PublisherAudiencesContextValue {
  audiences: MediaDataRoomJobHookResult<Audience[]>;
  isExportingAudience: Record<string, boolean>;
  downloadAudience: (audience: Audience) => void;
  exportAudienceAsDataset: (audience: Audience) => void;
  audienceSizes: AudienceSizesHookResult;
}

const PublisherAudiencesContext =
  createContext<PublisherAudiencesContextValue | null>(null);

PublisherAudiencesContext.displayName = "PublisherAudiencesContext";

export const usePublisherAudiences = () =>
  useSafeContext(PublisherAudiencesContext);

type PublisherAudiencesWrapperProps = React.PropsWithChildren;

const PublisherAudiencesWrapper = memo<PublisherAudiencesWrapperProps>(
  ({ children }) => {
    const { enqueueSnackbar } = useDataRoomSnackbar();
    const navigate = useNavigate();
    const apolloClient = useApolloClient();
    const { sessionManager } = useApiCore();
    const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
    const { dataRoomId, driverAttestationHash, isDeactivated, isPublisher } =
      useMediaDataRoom();
    const { advertiserDatasetHash, hasRequiredData, publisherDatasetsHashes } =
      useMediaDataRoomInsightsData();
    const publisherAudiencesQueryKey = useMemo(
      () => [
        "ab-dcr-publisher-audiences",
        dataRoomId,
        driverAttestationHash,
        advertiserDatasetHash,
      ],
      [dataRoomId, driverAttestationHash, advertiserDatasetHash]
    );
    const { data: session } = useQuery({
      enabled: driverAttestationHash != null,
      queryFn: async () => {
        return await sessionManager.get({ driverAttestationHash });
      },
      queryKey: ["ab-media", "session", driverAttestationHash],
    });
    const datasetsCacheKey = useMemo(() => {
      const cacheObj = datasetsCacheKeyCreator(
        advertiserDatasetHash,
        publisherDatasetsHashes
      );
      if (!cacheObj) {
        return null;
      }
      return computeCacheKeyString(cacheObj);
    }, [advertiserDatasetHash, publisherDatasetsHashes]);
    const transform = useCallback<MediaDataRoomJobResultTransform<Audience[]>>(
      async (zip: JSZip) => {
        const audiencesFile = zip.file("audiences.json");
        if (audiencesFile === null) {
          throw new Error("audiences.json not found in zip");
        }
        const audiencesFileStructure: AudiencesFileStructure = JSON.parse(
          await audiencesFile.async("string")
        );
        if (
          audiencesFileStructure.advertiser_manifest_hash !==
          advertiserDatasetHash
        ) {
          return [];
        } else {
          return audiencesFileStructure.audiences;
        }
      },
      [advertiserDatasetHash]
    );
    const audiences = useMediaDataRoomJob({
      cacheKey: datasetsCacheKey,
      dataRoomId,
      driverAttestationHash,
      key: "getAudiencesForPublisher",
      queryKeyPrefix: publisherAudiencesQueryKey.filter(Boolean) as string[],
      requestCreator: (dataRoomIdHex, scopeIdHex) => ({
        dataRoomIdHex,
        scopeIdHex,
      }),
      session,
      skip: !(
        !isDeactivated &&
        isPublisher &&
        !!dataRoomId &&
        !!driverAttestationHash &&
        hasRequiredData
      ),
      transform,
    });
    useEffect(() => {
      if (audiences.error) {
        setErrorSnackbarId(
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              audiences.error,
              "Unable to fetch audiences"
            )
          )
        );
      } else {
        setErrorSnackbarId((snackbarId) => {
          if (snackbarId) {
            return undefined;
          }
          return snackbarId;
        });
      }
    }, [enqueueSnackbar, audiences.error, setErrorSnackbarId]);
    const [isExportingAudience, setIsExportingAudience] = useState<
      Record<string, boolean>
    >({});
    const transformAudienceForDownload = useCallback<
      MediaDataRoomJobResultTransform<string>
    >(async (zip) => {
      const audienceUsersFile = zip.file("audience_users.csv");
      if (audienceUsersFile === null) {
        throw new Error("audience_users.csv not found in zip");
      }
      const audienceUsersCsv = await audienceUsersFile.async("string");
      if (!audienceUsersCsv) {
        throw new Error("Audience is empty");
      }
      return audienceUsersCsv;
    }, []);
    const [getAudienceUserListForPublisherLal] = useMediaDataRoomLazyJob({
      dataRoomId,
      driverAttestationHash,
      key: "getAudienceUserListForPublisherLal",
      session,
      transform: transformAudienceForDownload,
    });
    const [getAudienceUserListForPublisher] = useMediaDataRoomLazyJob({
      dataRoomId,
      driverAttestationHash,
      key: "getAudienceUserListForPublisher",
      session,
      transform: transformAudienceForDownload,
    });
    const downloadAudience = useCallback(
      async (audience: Audience) => {
        try {
          if (!datasetsCacheKey) {
            throw new Error(
              "Getting audience requires both the publisher and advertiser dataset uploaded and non-empty activated audiences config published"
            );
          }
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: true,
          }));
          const payloadParams = session!.compiler.abMedia.getParameterPayloads(
            audience.id,
            audiences.computeResults || []
          );
          let fileContent;
          if (payloadParams?.lal) {
            fileContent = await getAudienceUserListForPublisherLal({
              requestCreator: (dataRoomIdHex, scopeIdHex) => ({
                dataRoomIdHex,
                generateAudience: payloadParams.generate,
                lalAudience: payloadParams.lal!,
                scopeIdHex,
              }),
            });
          } else {
            fileContent = await getAudienceUserListForPublisher({
              requestCreator: (dataRoomIdHex, scopeIdHex) => ({
                dataRoomIdHex,
                generateAudience: payloadParams.generate,
                scopeIdHex,
              }),
            });
          }
          const reachPart =
            audience.kind === "lookalike" ? `-${audience.reach}%` : "";
          const audienceKind = audience.kind;
          const audienceType =
            audience.kind === "advertiser" ? audience.audience_type : "";
          const fileName = `Advertiser_${audienceKind}_${audienceType}${reachPart}_${format(
            new Date(),
            "dd_MM_yyyy HH_mm"
          )}.csv`;
          const file = new File([fileContent], fileName, {
            type: "application/octet-stream;charset=utf-8",
          });
          saveAs(file);
        } catch (error) {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to download audience"
            )
          );
        } finally {
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: false,
          }));
        }
      },
      [
        session,
        audiences.computeResults,
        enqueueSnackbar,
        datasetsCacheKey,
        getAudienceUserListForPublisherLal,
        getAudienceUserListForPublisher,
      ]
    );
    const exportAudienceAsDataset = useCallback(
      async (audience: Audience) => {
        try {
          if (!datasetsCacheKey) {
            throw new Error(
              "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
            );
          }
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: true,
          }));
          const payloadParams = session!.compiler.abMedia.getParameterPayloads(
            audience.id,
            audiences.computeResults || []
          );
          const isLalAudience = Boolean(payloadParams.lal);
          const computeNodeId: MediaDataRoomRequestKey = isLalAudience
            ? "getAudienceUserListForPublisherLal"
            : "getAudienceUserListForPublisher";
          const filename = `Advertiser_${audience.kind}_${audience.name}`;
          const parameters: { computeNodeName: string; content: string }[] = [
            {
              computeNodeName: "generate_audience.json",
              content: JSON.stringify(payloadParams.generate),
            },
          ];
          if (isLalAudience) {
            parameters.push({
              computeNodeName: "lal_audience.json",
              content: JSON.stringify(payloadParams.lal),
            });
          }
          await apolloClient.mutate({
            mutation: CreateDatasetImportDocument,
            variables: {
              input: {
                compute: {
                  computeNodeId: snakeCase(computeNodeId),
                  dataRoomId,
                  driverAttestationHash,
                  importFileWithName: "audience_users.csv",
                  isHighLevelNode: false,
                  parameters,
                  renameFileTo: filename,
                  shouldImportAllFiles: false,
                  shouldImportAsRaw: false,
                },
                datasetName: filename,
              },
            },
          });
          enqueueSnackbar(
            `"${audience.name}" result is being stored. Please check the status in the 'Datasets' page.`,
            {
              action: (
                <Button onClick={() => navigate("/datasets/external")}>
                  Go to Datasets
                </Button>
              ),
            }
          );
        } catch (error) {
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to export audience"
            )
          );
        } finally {
          setIsExportingAudience((current) => ({
            ...current,
            [audience.id]: false,
          }));
        }
      },
      [
        navigate,
        enqueueSnackbar,
        datasetsCacheKey,
        apolloClient,
        dataRoomId,
        session,
        driverAttestationHash,
        audiences.computeResults,
      ]
    );
    const audienceSizes = useAudienceSizes({
      audiences: audiences.computeResults,
      dataRoomId,
      datasetsCacheKey,
      driverAttestationHash,
      isPublisher: true,
      session,
    });
    const contextValue = useMemo<PublisherAudiencesContextValue>(
      () => ({
        audienceSizes,
        audiences,
        downloadAudience,
        exportAudienceAsDataset,
        isExportingAudience,
      }),
      [
        audiences,
        isExportingAudience,
        downloadAudience,
        exportAudienceAsDataset,
        audienceSizes,
      ]
    );
    return (
      <PublisherAudiencesContext.Provider value={contextValue}>
        {children}
      </PublisherAudiencesContext.Provider>
    );
  }
);

PublisherAudiencesWrapper.displayName = "PublisherAudiencesWrapper";

export default PublisherAudiencesWrapper;
