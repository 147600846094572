import {
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import { DataLabsWrapper } from "features/dataLabs";
import DataLabConnectionTable from "./DataLabConnectionTable";

interface DataLabConnectDialogProps {
  open: boolean;
  onCancel: () => void;
}

const DataLabConnectDialog: React.FC<DataLabConnectDialogProps> = ({
  open,
  onCancel,
}) => {
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalDialog>
        <DialogTitle>Provision datalab</DialogTitle>
        <Divider />
        <DialogContent>
          <DataLabsWrapper>
            <DataLabConnectionTable onCancel={onCancel} />
          </DataLabsWrapper>
        </DialogContent>
      </ModalDialog>
    </Modal>
  );
};

DataLabConnectDialog.displayName = "DataLabConnectDialog";

export default DataLabConnectDialog;
