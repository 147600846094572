import { type Session } from "@decentriq/core";
import { loadAsync } from "jszip";
import { useCallback, useMemo } from "react";
import {
  type AbMediaRequestValueOfKey,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
} from "./models";
import useMediaDataRoomRequest from "./useMediaDataRoomRequest";

export interface MediaDataRoomLazyJobHookPayload<
  T,
  K extends MediaDataRoomRequestKey,
> {
  /** @param dataRoomId data room id */
  dataRoomId: string;
  /** @param driverAttestationHash driver attestation hash */
  driverAttestationHash: string;
  /** @param key `AbMediaRequest` key; name of the request to be sent */
  key: K;
  /** @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key; if not provided, it will need to be provided in the payload of the runner otherwise an error will be thrown */
  requestCreator?: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
  /** @param transform function to transform the job result, it provides the zip file in `JSZip` format */
  transform: MediaDataRoomJobResultTransform<T>;
  /** @param session optional `Session`, if not provided, it will be fetched */
  session?: Session;
}

export type MediaDataRoomLazyJobRunner<
  T,
  K extends MediaDataRoomRequestKey,
> = (payload?: {
  /**
   *This function is used to create the request body for the given key, it has priority over the `requestCreator` provided in the hook options
   *
   * @param dataRoomIdHex data room id in the hex format
   * @param scopeIdHex data scope id in the hex format
   * @returns body of the request for the given key
   */
  requestCreator?: (
    dataRoomIdHex: string,
    scopeIdHex: string
  ) => AbMediaRequestValueOfKey<K>;
  /**
   *By providing options you can override the session and scope id used during the request execution
   * @param session `Session` object to be used for the request
   * @param scopeId data scope id to be used for the request
   */
  options?: { session?: Session; scopeId?: string };
}) => Promise<T>;
export type MediaDataRoomLazyJobHookResult<
  T,
  K extends MediaDataRoomRequestKey,
> = [MediaDataRoomLazyJobRunner<T, K>];

/**
 *This hook is used to create request, fetch the results of the job for using job ID and compute node name from request response on demand.
 *
 * @param key `AbMediaRequest` key; name of the request to be sent
 * @param dataRoomId data room id
 * @param driverAttestationHash driver attestation hash
 * @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key
 * @param session optional `Session`, if not provided, it will be fetched
 * @param transform function to transform the job result, it provides the zip file in `JSZip` format
 * @returns array with the runner function, which returns `Promise` with the transformed job result. If the runner is used on the `AbMediaRequest` key which does not return job ID and compute node name, an error will be thrown
 */
const useMediaDataRoomLazyJob = <T, K extends MediaDataRoomRequestKey>({
  key,
  dataRoomId,
  driverAttestationHash,
  requestCreator,
  transform,
  ...restPayload
}: MediaDataRoomLazyJobHookPayload<T, K>): MediaDataRoomLazyJobHookResult<
  T,
  K
> => {
  const [sendRequest, getSession] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key,
    requestCreator,
  });
  const run = useCallback<MediaDataRoomLazyJobRunner<T, K>>(
    async (payload) => {
      if (!payload?.requestCreator && !requestCreator) {
        throw new Error(
          `Failed to create request for "${key}". requestCreator is not provided neither in payload nor in hook options`
        );
      }
      const session =
        payload?.options?.session ??
        restPayload.session ??
        (await getSession());
      const response = await sendRequest({
        options: { scopeId: payload?.options?.scopeId, session },
        requestCreator: payload?.requestCreator ?? requestCreator!,
      });
      if (!("computeNodeName" in response) || !("jobIdHex" in response)) {
        throw new Error(`Invalid response for job ${key}`);
      }
      const result = await session.getComputationResult(
        {
          computeNodeId: response.computeNodeName,
          jobId: response.jobIdHex,
        },
        {
          interval: 1,
        }
      );
      const zip = await loadAsync(result);
      return transform(zip);
    },
    [
      key,
      restPayload.session,
      sendRequest,
      transform,
      getSession,
      requestCreator,
    ]
  );
  return useMemo(() => [run], [run]);
};

export default useMediaDataRoomLazyJob;
