import { Box, CircularProgress, Stack } from "@mui/joy";
import {
  MediaDataroomDataTabOverlap,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import AdvertiserDataNode from "../components/AdvertiserDataNode/AdvertiserDataNode";
import PublisherDataNode from "../components/PublisherDataNode/PublisherDataNode";
import { useMediaInsightsDcrData } from "../contexts/MediaInsightsDcrContext/MediaInsightsDcrContext";

const MediaDataRoomData: React.FC = () => {
  const {
    advertiserDatasetHash,
    publisherDatasetsHashes: {
      demographicsDatasetHash = null,
      embeddingsDatasetHash = null,
      segmentsDatasetHash = null,
      usersDatasetHash = null,
    } = {},
    retrieveDatasets,
    datasetsLoading,
  } = useMediaInsightsDcrData();
  const {
    supportedFeatures: { hideAbsoluteValues = false },
  } = usePublishedMediaInsightsDcr();

  if (datasetsLoading) {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }
  return (
    <>
      <Stack>
        <AdvertiserDataNode
          datasetHash={advertiserDatasetHash}
          retrieveDatasets={retrieveDatasets}
        />
        <PublisherDataNode
          demographicsDatasetHash={demographicsDatasetHash}
          embeddingsDatasetHash={embeddingsDatasetHash}
          retrieveDatasets={retrieveDatasets}
          segmentsDatasetHash={segmentsDatasetHash}
          usersDatasetHash={usersDatasetHash}
        />
      </Stack>
      {!hideAbsoluteValues && <MediaDataroomDataTabOverlap />}
    </>
  );
};

MediaDataRoomData.displayName = "MediaDataRoomData";

export default MediaDataRoomData;
