import { useApolloClient } from "@apollo/client";
import { CreateDatasetImportDocument } from "@decentriq/graphql/dist/types";
import { Key } from "@decentriq/utils";
import { Button } from "@mui/joy";
import { useMutation } from "@tanstack/react-query";
import format from "date-fns/format";
import saveAs from "file-saver";
import type JSZip from "jszip";
import snakeCase from "lodash/snakeCase";
import * as forge from "node-forge";
import { type SnackbarKey } from "notistack";
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { useApiCore } from "contexts";
import {
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom/contexts";
import {
  type AudienceSizesHookResult,
  type MediaDataRoomJobHookResult,
  type MediaDataRoomJobResultTransform,
  type MediaDataRoomRequestKey,
  useAudienceSizes,
  useMediaDataRoomJob,
  useMediaDataRoomLazyJob,
  useMediaDataRoomRequest,
} from "features/mediaDataRoom/hooks";
import {
  type Audience,
  type AudiencesFileStructure,
} from "features/mediaDataRoom/models";
import { datasetsCacheKeyCreator } from "features/mediaDataRoom/utils";
import {
  mapMediaDataRoomErrorToSnackbar,
  useDataRoomSnackbar,
  useSafeContext,
} from "hooks";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

export interface AddAudienceFnArgs {
  audience: Audience;
  excludeSeedAudience?: boolean;
  onSuccess?: () => void;
}

export interface AdvertiserAudiencesContextValue {
  audiences: MediaDataRoomJobHookResult<Audience[]>;
  saveAudience: (params: AddAudienceFnArgs) => void;
  isSavingAudience: boolean;
  publishAudience: (audience: Audience) => void;
  isPublishingAudience: boolean;
  isExportingAudience: Record<string, boolean>;
  downloadAudience: (audience: Audience) => void;
  exportAudienceAsDataset: (audience: Audience) => void;
  isDeletingAudience: boolean;
  deleteAudience: (audience: Audience) => void;
  getAudiencePreqrequisites: (audienceId: string) => Audience[] | undefined;
  audienceSizes: AudienceSizesHookResult;
}

const AdvertiserAudiencesContext =
  createContext<AdvertiserAudiencesContextValue | null>(null);

AdvertiserAudiencesContext.displayName = "AdvertiserAudiencesContext";

export const useAdvertiserAudiences = () =>
  useSafeContext(AdvertiserAudiencesContext);

const AdvertiserAudiencesWrapper: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    dataRoomId,
    driverAttestationHash,
    isDeactivated,
    isAdvertiser,
    isObserver,
    isAgency,
  } = useMediaDataRoom();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const apolloClient = useApolloClient();
  const navigate = useNavigate();
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const { client } = useApiCore();
  const { advertiserDatasetHash, publisherDatasetsHashes, session } =
    useMediaDataRoomInsightsData();
  const [publishAudiencesJson] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key: "publishAudiencesJson",
  });
  const advertiserAudiencesQueryKey = useMemo(
    () => [
      "ab-dcr-advertiser-audiences",
      dataRoomId,
      driverAttestationHash,
      advertiserDatasetHash,
    ],
    [dataRoomId, driverAttestationHash, advertiserDatasetHash]
  );
  const datasetsCacheKey = useMemo(() => {
    const cacheObj = datasetsCacheKeyCreator(
      advertiserDatasetHash,
      publisherDatasetsHashes
    );
    if (!cacheObj) {
      return null;
    }
    return computeCacheKeyString(cacheObj);
  }, [advertiserDatasetHash, publisherDatasetsHashes]);
  const transform = useCallback<MediaDataRoomJobResultTransform<Audience[]>>(
    async (zip: JSZip) => {
      const audiencesFile = zip.file("audiences.json");
      if (audiencesFile === null) {
        throw new Error("audiences.json not found in zip");
      }
      const audiencesFileStructure: AudiencesFileStructure = JSON.parse(
        await audiencesFile.async("string")
      );
      if (
        audiencesFileStructure.advertiser_manifest_hash !== null &&
        audiencesFileStructure.advertiser_manifest_hash !==
          advertiserDatasetHash
      ) {
        return [];
      }
      return audiencesFileStructure.audiences;
    },
    [advertiserDatasetHash]
  );

  const audiences = useMediaDataRoomJob({
    cacheKey: datasetsCacheKey,
    dataRoomId,
    driverAttestationHash,
    key: "getAudiencesForAdvertiser",
    queryKeyPrefix: advertiserAudiencesQueryKey,
    requestCreator: (dataRoomIdHex, scopeIdHex) => ({
      dataRoomIdHex,
      scopeIdHex,
    }),
    session,
    skip: !(
      !!dataRoomId &&
      !!driverAttestationHash &&
      (isAdvertiser || isObserver || isAgency) &&
      !isDeactivated &&
      !!advertiserDatasetHash
    ),
    transform,
  });
  useEffect(() => {
    if (audiences.error) {
      const snackbarId = enqueueSnackbar(
        ...mapMediaDataRoomErrorToSnackbar(
          audiences.error,
          "Unable to fetch audiences"
        )
      );
      setErrorSnackbarId(snackbarId);
    } else {
      setErrorSnackbarId((snackbarId) => {
        if (snackbarId) {
          closeSnackbar(snackbarId);
        }
        return undefined;
      });
    }
  }, [audiences.error, enqueueSnackbar, closeSnackbar, setErrorSnackbarId]);
  const { disableSizeEstimationForAudience, ...restOfAudienceSizes } =
    useAudienceSizes({
      audiences: audiences.computeResults,
      dataRoomId,
      datasetsCacheKey,
      driverAttestationHash,
      session,
    });
  const saveAudiencesToFile = useMutation({
    mutationFn: async ({
      nextAudiences,
    }: {
      nextAudiences: Audience[];
      prevAudiences: Audience[];
    }) => {
      audiences.setCacheData(nextAudiences);
      if (
        !advertiserDatasetHash ||
        !publisherDatasetsHashes.matchingDatasetHash
      ) {
        throw new Error("Advertiser dataset not published");
      }
      const activatedAudiencesConfig: AudiencesFileStructure = {
        advertiser_manifest_hash: advertiserDatasetHash,
        audiences: nextAudiences,
        matching_manifest_hash: publisherDatasetsHashes.matchingDatasetHash,
        ...(publisherDatasetsHashes.embeddingsDatasetHash
          ? {
              embeddings_manifest_hash:
                publisherDatasetsHashes.embeddingsDatasetHash,
            }
          : {}),
        ...(publisherDatasetsHashes.demographicsDatasetHash
          ? {
              demographics_manifest_hash:
                publisherDatasetsHashes.demographicsDatasetHash,
            }
          : {}),
        ...(publisherDatasetsHashes.segmentsDatasetHash
          ? {
              segments_manifest_hash:
                publisherDatasetsHashes.segmentsDatasetHash,
            }
          : {}),
      };
      const key = new Key();
      const activatedAudienceConfigManifestHash = await client.uploadDataset(
        new TextEncoder().encode(JSON.stringify(activatedAudiencesConfig)),
        key,
        "audiences.json",
        { isAccessory: true }
      );
      await publishAudiencesJson({
        requestCreator: (dataRoomIdHex, scopeIdHex) => ({
          dataRoomIdHex,
          datasetHashHex: activatedAudienceConfigManifestHash,
          encryptionKeyHex: forge.util.binary.hex.encode(key.material),
          scopeIdHex,
        }),
      });
    },
    onError: (_, { prevAudiences }) => {
      audiences.setCacheData(prevAudiences);
    },
  });
  const saveAudience = useCallback(
    async ({ audience, onSuccess }: AddAudienceFnArgs) => {
      const oldAudiences: Audience[] = audiences.computeResults ?? [];
      const newAudiences: Audience[] = [audience, ...oldAudiences];
      saveAudiencesToFile.mutate(
        {
          nextAudiences: newAudiences,
          prevAudiences: oldAudiences,
        },
        {
          onError: (error) => {
            enqueueSnackbar(
              ...mapMediaDataRoomErrorToSnackbar(
                error,
                "Failed to generate audience."
              )
            );
          },
          onSuccess,
        }
      );
    },
    [audiences.computeResults, enqueueSnackbar, saveAudiencesToFile]
  );
  const getAudiencePreqrequisites = useCallback(
    (audienceId: string): Audience[] | undefined => {
      const currentAudiences = audiences.computeResults ?? [];
      return session?.compiler.abMedia
        .getAudiencePreqrequisites(audienceId, currentAudiences)
        .map((id) => currentAudiences.find((a) => a.id === id))
        .filter(Boolean) as Audience[];
    },
    [audiences.computeResults, session]
  );
  const deleteAudience = useCallback(
    async (audience: Audience) => {
      try {
        const currentAudiences = audiences.computeResults ?? [];
        const dependencies = getAudiencePreqrequisites(audience.id);
        const nextAudiences: Audience[] = currentAudiences.filter(
          (a) =>
            a.id !== audience.id && !dependencies?.some(({ id }) => id === a.id)
        );
        await saveAudiencesToFile.mutateAsync({
          nextAudiences,
          prevAudiences: currentAudiences,
        });
        disableSizeEstimationForAudience([
          audience.id,
          ...(dependencies?.map(({ id }) => id) ?? []),
        ]);
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Failed to delete audience."
          )
        );
      }
    },
    [
      audiences.computeResults,
      enqueueSnackbar,
      saveAudiencesToFile,
      getAudiencePreqrequisites,
      disableSizeEstimationForAudience,
    ]
  );
  const publishAudience = useCallback(
    async (audience: Audience) => {
      try {
        const currentAudiences = audiences.computeResults ?? [];
        const dependencyIds = session!.compiler.abMedia.getAudienceDependencies(
          audience.id,
          currentAudiences
        );
        const nextAudiences: Audience[] = currentAudiences.map((a) =>
          a.id === audience.id
            ? { ...a, status: "published" }
            : dependencyIds.includes(a.id) && a.status !== "published"
              ? { ...a, status: "published_as_intermediate" }
              : a
        );
        await saveAudiencesToFile.mutateAsync({
          nextAudiences,
          prevAudiences: currentAudiences,
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Failed to publish audience."
          )
        );
      }
    },
    [audiences.computeResults, enqueueSnackbar, saveAudiencesToFile, session]
  );
  const [isExportingAudience, setIsExportingAudience] = useState<
    Record<string, boolean>
  >({});
  const transformAudienceForDownload = useCallback<
    MediaDataRoomJobResultTransform<string>
  >(async (zip) => {
    const audienceUsersFile = zip.file("audience_users.csv");
    if (audienceUsersFile === null) {
      throw new Error("audience_users.csv not found in zip");
    }
    const audienceUsersCsv = await audienceUsersFile.async("string");
    if (!audienceUsersCsv) {
      throw new Error("Audience is empty");
    }
    return audienceUsersCsv;
  }, []);
  const [getAudienceUserListForAdvertiserLal] = useMediaDataRoomLazyJob({
    dataRoomId,
    driverAttestationHash,
    key: "getAudienceUserListForAdvertiserLal",
    session,
    transform: transformAudienceForDownload,
  });
  const [getAudienceUserListForAdvertiser] = useMediaDataRoomLazyJob({
    dataRoomId,
    driverAttestationHash,
    key: "getAudienceUserListForAdvertiser",
    session,
    transform: transformAudienceForDownload,
  });
  const downloadAudience = useCallback(
    async (audience: Audience) => {
      try {
        if (!datasetsCacheKey) {
          throw new Error(
            "Getting audience requires both the publisher and advertiser dataset uploaded and non-empty activated audiences config published"
          );
        }
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: true,
        }));
        const payloadParams = session!.compiler.abMedia.getParameterPayloads(
          audience.id,
          audiences.computeResults || []
        );
        let fileContent;
        if (payloadParams?.lal) {
          fileContent = await getAudienceUserListForAdvertiserLal({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              lalAudience: payloadParams.lal!,
              scopeIdHex,
            }),
          });
        } else {
          fileContent = await getAudienceUserListForAdvertiser({
            requestCreator: (dataRoomIdHex, scopeIdHex) => ({
              dataRoomIdHex,
              generateAudience: payloadParams.generate,
              scopeIdHex,
            }),
          });
        }
        const reachPart =
          audience.kind === "lookalike" ? `-${audience.reach}%` : "";
        const audienceKind = audience.kind;
        const audienceType =
          audience.kind === "advertiser" ? audience.audience_type : "";
        const fileName = `Advertiser_${audienceKind}_${audienceType}${reachPart}_${format(
          new Date(),
          "dd_MM_yyyy HH_mm"
        )}.csv`;
        const file = new File([fileContent], fileName, {
          type: "application/octet-stream;charset=utf-8",
        });
        saveAs(file);
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(
            error,
            "Unable to download audience"
          )
        );
      } finally {
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: false,
        }));
      }
    },
    [
      session,
      audiences.computeResults,
      enqueueSnackbar,
      datasetsCacheKey,
      getAudienceUserListForAdvertiserLal,
      getAudienceUserListForAdvertiser,
    ]
  );
  const exportAudienceAsDataset = useCallback(
    async (audience: Audience) => {
      try {
        if (!datasetsCacheKey) {
          throw new Error(
            "Storing an audience as dataset requires both the publisher and advertiser dataset uploaded"
          );
        }
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: true,
        }));
        const payloadParams = session!.compiler.abMedia.getParameterPayloads(
          audience.id,
          audiences.computeResults || []
        );
        const isLalAudience = Boolean(payloadParams.lal);
        const computeNodeId: MediaDataRoomRequestKey = isLalAudience
          ? "getAudienceUserListForAdvertiserLal"
          : "getAudienceUserListForAdvertiser";
        const filename = `Advertiser_${audience.kind}_${audience.name}`;
        const parameters: { computeNodeName: string; content: string }[] = [
          {
            computeNodeName: "generate_audience.json",
            content: JSON.stringify(payloadParams.generate),
          },
        ];
        if (isLalAudience) {
          parameters.push({
            computeNodeName: "lal_audience.json",
            content: JSON.stringify(payloadParams.lal),
          });
        }
        await apolloClient.mutate({
          mutation: CreateDatasetImportDocument,
          variables: {
            input: {
              compute: {
                computeNodeId: snakeCase(computeNodeId),
                dataRoomId,
                driverAttestationHash,
                importFileWithName: "audience_users.csv",
                isHighLevelNode: false,
                parameters,
                renameFileTo: filename,
                shouldImportAllFiles: false,
                shouldImportAsRaw: false,
              },
              datasetName: filename,
            },
          },
        });
        enqueueSnackbar(
          `"${audience.name}" result is being stored. Please check the status in the 'Datasets' page.`,
          {
            action: (
              <Button onClick={() => navigate("/datasets/external")}>
                Go to Datasets
              </Button>
            ),
          }
        );
      } catch (error) {
        enqueueSnackbar(
          ...mapMediaDataRoomErrorToSnackbar(error, "Unable to export audience")
        );
      } finally {
        setIsExportingAudience((current) => ({
          ...current,
          [audience.id]: false,
        }));
      }
    },
    [
      navigate,
      enqueueSnackbar,
      datasetsCacheKey,
      session,
      apolloClient,
      audiences.computeResults,
      dataRoomId,
      driverAttestationHash,
    ]
  );
  const contextValue = useMemo<AdvertiserAudiencesContextValue>(
    () => ({
      audienceSizes: {
        disableSizeEstimationForAudience,
        ...restOfAudienceSizes,
      },
      audiences,
      deleteAudience,
      downloadAudience,
      exportAudienceAsDataset,
      getAudiencePreqrequisites,
      isDeletingAudience: saveAudiencesToFile.isPending,
      isExportingAudience,
      isPublishingAudience: saveAudiencesToFile.isPending,
      isSavingAudience: saveAudiencesToFile.isPending,
      publishAudience,
      refetchAudiences: audiences.retry,
      saveAudience,
      viewAudiencesError: audiences.error,
    }),
    [
      audiences,
      deleteAudience,
      getAudiencePreqrequisites,
      saveAudiencesToFile.isPending,
      publishAudience,
      saveAudience,
      downloadAudience,
      exportAudienceAsDataset,
      isExportingAudience,
      disableSizeEstimationForAudience,
      restOfAudienceSizes,
    ]
  );
  return (
    <AdvertiserAudiencesContext.Provider value={contextValue}>
      {children}
    </AdvertiserAudiencesContext.Provider>
  );
};

export default AdvertiserAudiencesWrapper;
