// TODO: Fix joy migration
import { Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  type InsightsBasicViewFormValues,
  MediaDataRoomInsightsBasicView,
  MediaDataRoomInsightsComparison,
  MediaDataRoomInsightsDetailedView,
  MediaDataRoomInsightsExplorer,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";
import { useMediaDataRoomOverlapInsights } from "features/mediaDataRoom/contexts";

enum InsightsTabs {
  Basic = "Basic",
  Detailed = "Detailed",
  Comparison = "Comparison",
  Explorer = "Explorer",
}

const MediaDataRoomInsightsTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState<InsightsTabs>(InsightsTabs.Basic);
  const handleTabChange = useCallback(
    (event: React.SyntheticEvent<Element, Event>, newValue: InsightsTabs) =>
      setActiveTab(newValue),
    [setActiveTab]
  );
  const { audienceType } = useMediaDataRoomOverlapInsights();
  const form = useForm<InsightsBasicViewFormValues>({
    defaultValues: {
      audienceType: audienceType || "",
      genderChartValueKey: "shareInOverlap",
    },
    mode: "onChange",
  });
  const { publisherDatasetsHashes: { demographicsDatasetHash } = {} } =
    useMediaDataRoomInsightsData();
  return (
    <Tabs onChange={handleTabChange} value={activeTab}>
      <TabList>
        <Tab value={InsightsTabs.Basic}>Basic</Tab>
        {demographicsDatasetHash && (
          <Tab value={InsightsTabs.Detailed}>Detailed</Tab>
        )}
        <Tab value={InsightsTabs.Comparison}>Comparison</Tab>
        <Tab value={InsightsTabs.Explorer}>Explorer</Tab>
      </TabList>
      <TabPanel value={InsightsTabs.Basic}>
        {/* This is temp fix, form provider should be inside of the basic view. Issue is in audienceType being not updated inside overlap statistics */}
        <FormProvider {...form}>
          <MediaDataRoomInsightsBasicView />
        </FormProvider>
      </TabPanel>
      {demographicsDatasetHash && (
        <TabPanel value={InsightsTabs.Detailed}>
          <MediaDataRoomInsightsDetailedView />
        </TabPanel>
      )}
      <TabPanel value={InsightsTabs.Comparison}>
        <MediaDataRoomInsightsComparison />
      </TabPanel>
      <TabPanel value={InsightsTabs.Explorer}>
        <MediaDataRoomInsightsExplorer />
      </TabPanel>
    </Tabs>
  );
};
MediaDataRoomInsightsTabs.displayName = "MediaDataRoomInsightsTabs";

export default memo(MediaDataRoomInsightsTabs);
