import {
  AudienceCombinatorOperator,
  AudienceCondition,
  type AudienceFilters,
  AudienceFiltersBooleanOperator,
  type AudienceRulesTree,
} from "@decentriq/components";
import { type parameter_payloads as ddcParameterPayloads } from "ddc";
import { type RuleBasedAudience } from "features/mediaDataRoom/models";

const mapAudienceFiltersOperatorToBooleanOp: Record<
  AudienceFiltersBooleanOperator,
  ddcParameterPayloads.BooleanOp
> = {
  [AudienceFiltersBooleanOperator.AND]: "and",
  [AudienceFiltersBooleanOperator.OR]: "or",
};

const mapAudienceConditionToFilterOperator: Record<
  AudienceCondition,
  ddcParameterPayloads.FilterOperator
> = {
  [AudienceCondition.CONTAINS_ANY_OF]: "contains_any_of",
  [AudienceCondition.CONTAINS_NONE_OF]: "contains_none_of",
  [AudienceCondition.CONTAINS_ALL_OF]: "contains_all_of",
  [AudienceCondition.EMPTY]: "empty",
  [AudienceCondition.NOT_EMPTY]: "not_empty",
};

const mapAudienceCombinatorOperatorToCombineOperator: Record<
  AudienceCombinatorOperator,
  ddcParameterPayloads.CombineOperator
> = {
  [AudienceCombinatorOperator.INTERSECT]: "intersect",
  [AudienceCombinatorOperator.UNION]: "union",
  [AudienceCombinatorOperator.DIFF]: "diff",
};

const mapAudienceFiltersToDdcAudienceFilters = (
  filters: AudienceFilters
): ddcParameterPayloads.AudienceFilters => ({
  boolean_op: mapAudienceFiltersOperatorToBooleanOp[filters.operator],
  filters: filters.filters.map(({ condition, attribute, values }) => ({
    attribute: attribute ?? "",
    operator:
      mapAudienceConditionToFilterOperator[
        condition ?? AudienceCondition.CONTAINS_ANY_OF
      ],
    values: values ?? [],
  })),
});

export const mapAudienceRulesTreeToRulesBasedAudience = (
  { name, source_ref, filters, combine }: AudienceRulesTree,
  id?: string,
  createdAt?: string
): RuleBasedAudience => ({
  combine: combine
    ? combine.map(({ operator, source_ref, filters }) => ({
        filters: filters
          ? mapAudienceFiltersToDdcAudienceFilters(filters)
          : null,
        operator: mapAudienceCombinatorOperatorToCombineOperator[operator],
        source_ref: source_ref ?? "",
      }))
    : null,
  created_at: createdAt ?? new Date().toISOString(),
  filters: filters ? mapAudienceFiltersToDdcAudienceFilters(filters) : null,
  id: id ?? crypto.randomUUID(),
  kind: "rulebased",
  name,
  source_ref,
  status: "ready",
});
