import { DqTable, InfoTooltip } from "@decentriq/components";
import { faBan } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography } from "@mui/joy";
import { type MRT_ColumnDef } from "material-react-table";
import { memo, useMemo } from "react";
import { EmptyStateContainer } from "features/mediaDataRoom/components";
import { type AggregationDataModel } from "features/mediaDataRoom/models";
import { formatNumber } from "features/mediaDataRoom/utils";
import AffinityRatioChip from "./AffinityRatioChip";

type MediaDataRoomInsightsTableProps = {
  tableData: AggregationDataModel[] | Partial<AggregationDataModel>[];
  isEmpty: boolean;
};

const MediaDataRoomInsightsTable: React.FC<MediaDataRoomInsightsTableProps> = ({
  tableData = [],
  isEmpty = false,
}) => {
  const overlapInsightsColumns = useMemo(
    () =>
      (
        [
          {
            accessorKey: "interest",
            header: "Segment",
            id: "interest",
            size: 300,
            visible: tableData.some(
              ({ interest }: Partial<AggregationDataModel>) => !!interest
            ),
          },
          {
            accessorKey: "age",
            header: "Age",
            id: "age",
            maxSize: 150,
            visible: tableData.some(
              ({ age }: Partial<AggregationDataModel>) => !!age
            ),
          },
          {
            accessorKey: "gender",
            header: "Gender",
            id: "gender",
            maxSize: 150,
            visible: tableData.some(
              ({ gender }: Partial<AggregationDataModel>) => !!gender
            ),
          },
          {
            Cell: ({ cell }) => {
              const affinityRatioValue = cell.getValue() as
                | number
                | null
                | undefined;
              if (typeof affinityRatioValue !== "number") {
                return <span style={{ marginLeft: "4px" }}>N/A</span>;
              }
              return <AffinityRatioChip value={affinityRatioValue} />;
            },
            Header: ({ column }) => (
              <Box alignItems="center" display="flex" justifyContent="center">
                {column.columnDef.header}
                <InfoTooltip
                  IconButtonProps={{
                    sx: { minWidth: "20px !important", top: "0 !important" },
                  }}
                  tooltip="This is the ratio “Share in overlap” / “Share in publisher audience”. The higher this value, the more likely it is that a person in this segment is interested in the Advertiser’s product compared to a random person in the addressable publisher audience."
                />
              </Box>
            ),
            accessorKey: "affinityRatio",
            header: "Affinity",
            id: "affinityRatio",
            maxSize: 150,
            visible: tableData.some(
              ({ affinityRatio }: Partial<AggregationDataModel>) =>
                !!affinityRatio
            ),
          },
          {
            Cell: ({ cell }) => {
              const shareInOverlapValue = cell.getValue() as
                | number
                | undefined
                | null;
              if (typeof shareInOverlapValue !== "number") {
                return "N/A";
              }
              return `${(shareInOverlapValue * 100).toFixed(1)}%`;
            },
            Header: () => (
              <Box alignItems="center" display="flex" justifyContent="center">
                <Typography fontWeight={600} level="body-sm">
                  Share in <br /> overlap
                  <InfoTooltip
                    IconButtonProps={{
                      sx: { minWidth: "20px !important" },
                    }}
                    tooltip="This is the share of individuals as a fraction of all individuals in the overlap."
                  />
                </Typography>
              </Box>
            ),
            accessorKey: "shareInOverlap",
            id: "shareInOverlap",
            visible: tableData.some(
              ({ shareInOverlap }: Partial<AggregationDataModel>) =>
                !!shareInOverlap
            ),
          },
          {
            Cell: ({ cell }) => {
              const shareInAddressableAudienceValue = cell.getValue() as
                | number
                | undefined
                | null;
              if (typeof shareInAddressableAudienceValue !== "number") {
                return "N/A";
              }
              return `${(shareInAddressableAudienceValue * 100).toFixed(1)}%`;
            },
            Header: () => (
              <Box alignItems="center" display="flex" justifyContent="center">
                <Typography fontWeight={600} level="body-sm">
                  Share in <br /> publisher audience
                  <InfoTooltip
                    IconButtonProps={{
                      sx: { minWidth: "20px !important" },
                    }}
                    tooltip="This is the share of individuals as a fraction of all individuals in the addressable publisher audience."
                  />
                </Typography>
              </Box>
            ),
            accessorKey: "shareInAddressableAudience",
            id: "shareInAddressableAudience",
            minSize: 250,
            visible: tableData.some(
              ({ shareInAddressableAudience }: Partial<AggregationDataModel>) =>
                !!shareInAddressableAudience
            ),
          },
          {
            Cell: ({ cell }) => {
              const addressableAudienceSizeValue = cell.getValue() as number;
              const roundedAddressableAudienceSizeValue =
                Math.round(addressableAudienceSizeValue / 10) * 10;
              return `~${formatNumber(roundedAddressableAudienceSizeValue)}`;
            },
            Header: () => (
              <Box alignItems="center" display="flex" justifyContent="center">
                <Typography fontWeight={600} level="body-sm">
                  Addressable users in <br /> publisher audience
                  <InfoTooltip
                    IconButtonProps={{
                      sx: { minWidth: "20px !important" },
                    }}
                    tooltip={
                      <span>
                        This is the amount of people that the publisher is able
                        to address in this segment.
                        <br />
                        <br />
                        We round these numbers and add a small amount of noise
                        to these totals to protect individual privacy. We sample
                        from a Laplacian Mechanism, with a scale factor of 10.
                        This means that there is a 63% chance of adding noise
                        between -10 and 10 to any given count of users. The
                        chance of adding more noise falls off rapidly, there is
                        less than a 2% chance that the noise is larger than 40.
                      </span>
                    }
                  />
                </Typography>
              </Box>
            ),
            accessorKey: "addressableAudienceSize",
            id: "addressableAudienceSize",
            minSize: 250,
            visible: tableData.some(
              ({ addressableAudienceSize }: Partial<AggregationDataModel>) =>
                !!addressableAudienceSize
            ),
          },
        ] as (MRT_ColumnDef<Partial<AggregationDataModel>> & {
          visible?: boolean;
        })[]
      ).filter(({ visible }) => !!visible),
    [tableData]
  );

  if (isEmpty) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          All segments are too small to be displayed.
        </Typography>
        <Typography level="body-sm">
          Segments which are too small are not displayed for privacy.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <DqTable
      columns={overlapInsightsColumns}
      data={tableData}
      initialState={{
        // https://github.com/KevinVandy/material-react-table/issues/143
        columnOrder: [
          "interest",
          "age",
          "gender",
          "affinityRatio",
          "shareInOverlap",
          "shareInAddressableAudience",
          "addressableAudienceSize",
        ],
      }}
      localization={{
        noRecordsToDisplay: "The selected Advertiser audience is too small",
      }}
      muiTablePaperProps={{
        sx: { flex: 3, height: "100%", overflow: "auto" },
      }}
    />
  );
};

MediaDataRoomInsightsTable.displayName = "MediaDataRoomInsightsTable";

export default memo(MediaDataRoomInsightsTable);
