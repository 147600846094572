import { DqCard, DqCardContent } from "@decentriq/components";
import { faBinoculars, faSliders } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Grid, Typography } from "@mui/joy";
import { memo, useState } from "react";
import {
  AdvertiserAudienceGeneratorType,
  useAdvertiserAudienceGenerator,
} from "../..";
import {
  AdvertiserAudienceGeneratorHeader,
  AudienceGeneratorStepActionsWrapper,
  AudienceGeneratorStepSectionDescription,
  AudienceGeneratorStepSectionTitle,
} from "..";

const AdvertiserAudienceGeneratorTypeSelection = memo(() => {
  const { handleBackStep, selectGeneratorType } =
    useAdvertiserAudienceGenerator();
  const [generatorType, setGeneratorType] =
    useState<AdvertiserAudienceGeneratorType | null>(null);
  return (
    <Grid container={true}>
      <Grid mb={3} md={7} xs={12}>
        <AdvertiserAudienceGeneratorHeader />
      </Grid>
      <Grid mb={2} md={7} xs={12}>
        <AudienceGeneratorStepSectionTitle>
          1. Audience type
        </AudienceGeneratorStepSectionTitle>
        <AudienceGeneratorStepSectionDescription>
          Select the type of audience to create: Rule-based for targeted
          precision or AI lookalike to find new customers similar to your top
          ones.
        </AudienceGeneratorStepSectionDescription>
      </Grid>
      <Grid columnSpacing={2} container={true} md={7} xs={12}>
        <Grid xs={6}>
          <DqCard
            onSelect={() =>
              setGeneratorType(AdvertiserAudienceGeneratorType.RULES_BASED)
            }
            selected={
              generatorType === AdvertiserAudienceGeneratorType.RULES_BASED
            }
            sx={{ height: "100%" }}
          >
            <DqCardContent>
              <FontAwesomeIcon icon={faSliders} size="3x" />
              <Typography
                fontWeight={500}
                level="body-md"
                mb={1}
                mt={1}
                textColor="inherit"
              >
                Rule-based audience
              </Typography>
              <Typography
                level="body-sm"
                textAlign="center"
                textColor="inherit"
              >
                Combine and filter your existing audiences.
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
        <Grid xs={6}>
          <DqCard
            onSelect={() =>
              setGeneratorType(AdvertiserAudienceGeneratorType.LOOKALIKE)
            }
            selected={
              generatorType === AdvertiserAudienceGeneratorType.LOOKALIKE
            }
            sx={{ height: "100%" }}
          >
            <DqCardContent>
              <FontAwesomeIcon icon={faBinoculars} size="3x" />
              <Typography
                fontWeight={500}
                level="body-md"
                mb={1}
                mt={1}
                textColor="inherit"
              >
                AI lookalike audience
              </Typography>
              <Typography
                level="body-sm"
                textAlign="center"
                textColor="inherit"
              >
                Build an audience with the users most similar to your seed
                audience.
              </Typography>
            </DqCardContent>
          </DqCard>
        </Grid>
      </Grid>
      <AudienceGeneratorStepActionsWrapper>
        <Button onClick={handleBackStep} sx={{ mr: 1 }}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={generatorType === null}
          onClick={() => selectGeneratorType(generatorType!)}
          variant="solid"
        >
          Continue
        </Button>
      </AudienceGeneratorStepActionsWrapper>
    </Grid>
  );
});

AdvertiserAudienceGeneratorTypeSelection.displayName =
  "AdvertiserAudienceGeneratorTypeSelection";

export default AdvertiserAudienceGeneratorTypeSelection;
