/*
// TODO: Fix joy migration
import {
  faArrowLeft,
  faBan,
  faCircleExclamation,
  faSparkles,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Option,
  Select,
  Typography,
} from "@mui/joy";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAudiencePresentation } from "features/legacyMediaDataRoom/types";
import {
  EmptyStateContainer,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import {
  AudienceGeneratorReachSlider,
  AudienceQualityStatistics,
  AudienceQualityStatisticsItemBox,
  ExcludeSeedAudienceCheckbox,
  useAdvertiserAudienceGenerator,
} from "features/MediaInsightsDcr/components/AdvertiserAudienceGenerator";
import { useOrganizationPreferences } from "hooks";

const AdvertiserAudienceGenerator: React.FC = () => {
  const {
    selectedAudienceType,
    setSelectedAudienceType,
    generateLookalikeAudience,
    generateLookalikeAudienceLoading,
    openExcludeSeedAudienceInfoDialog,
    selectedLookalikeAudience,
    activatedLookalikeAudiences = [],
    availableAudiences: {
      computeResults: { audienceTypes = [] } = {},
      status: availableAudiencesStatus,
      error: availableAudiencesError,
      retry: retryAvailableAudiences,
    },
  } = useAdvertiserAudienceGenerator();

  const [retryInProgress, setRetryInProgress] = useState(false);

  const { dataRoomId } = usePublishedMediaInsightsDcr();
  const navigate = useNavigate();
  const formatter = Intl.NumberFormat("en", { notation: "compact" });
  const {
    isDeactivated,
    supportedFeatures: { enableExtendedLalQualityStats = false },
  } = usePublishedMediaInsightsDcr();

  const { allowExcludingSeedAudience } = useOrganizationPreferences();

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    try {
      await retryAvailableAudiences();
    } finally {
      setRetryInProgress(false);
    }
  }, [retryAvailableAudiences, isDeactivated]);

  if (availableAudiencesStatus !== "COMPLETED") {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress sx={{ "--CircularProgress-size": "1.5rem" }} />
      </Box>
    );
  }

  if (isDeactivated || availableAudiencesError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography level="body-sm" mt={1}>
          Audience data could not be retrieved
        </Typography>
        {!isDeactivated && (
          <Button
            color="secondary"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
          >
            Retry
          </Button>
        )}
      </EmptyStateContainer>
    );
  }

  if (audienceTypes.length === 0) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          There are not enough individuals in the overlap.
        </Typography>
        <Typography level="body-sm">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }

  return (
    <Grid container={true}>
      <Grid container={true} mb={2}>
        <Button
          color="secondary"
          onClick={() => navigate(`/datarooms/mi/${dataRoomId}/activation`)}
          startDecorator={<FontAwesomeIcon icon={faArrowLeft} />}
        >
          Back
        </Button>
      </Grid>
      <Grid container={true} xs={12}>
        <Grid xs={7}>
          <Typography level="title-md">
            1. Select Your Advertiser Audience
          </Typography>
          <Typography level="body-sm">
            Choose the audience that will be used to seed your lookalike model.
          </Typography>
        </Grid>
        <Grid xs={5}>
          <FormControl>
            <FormLabel>Advertiser Audience</FormLabel>
            <Select
              onChange={(event, value) => setSelectedAudienceType(value)}
              renderValue={getAudiencePresentation}
              value={selectedAudienceType || ""}
            >
              {audienceTypes.map((audienceType) => {
                return (
                  <Option key={audienceType} value={audienceType}>
                    {getAudiencePresentation({ value: audienceType })}
                  </Option>
                );
              })}
            </Select>
          </FormControl>
          {enableExtendedLalQualityStats && (
            <Box display="flex" gap={2} mt={2}>
              <AudienceQualityStatisticsItemBox flex={1}>
                <Typography level="body-sm">Addressable audience</Typography>
                <Typography fontWeight={800}>
                  {formatter.format(
                    selectedLookalikeAudience?.addressable_audience_size || 0
                  )}
                </Typography>
              </AudienceQualityStatisticsItemBox>
              <AudienceQualityStatisticsItemBox flex={1}>
                <Typography level="body-sm">Seed audience</Typography>
                <Typography fontWeight={800}>
                  {formatter.format(
                    selectedLookalikeAudience?.seed_audience_size || 0
                  )}
                </Typography>
              </AudienceQualityStatisticsItemBox>
            </Box>
          )}
        </Grid>
      </Grid>
      <Divider
        sx={({ spacing }) => ({ margin: spacing(4, 0), width: "100%" })}
      />
      <Grid container={true} xs={12}>
        <Grid xs={7}>
          <Typography level="title-md">2. Define the Reach</Typography>
          <Typography level="body-sm">
            Set the precision and reach for your lookalike model
          </Typography>
        </Grid>
        <Grid xs={5}>
          <AudienceGeneratorReachSlider />
          {allowExcludingSeedAudience && <ExcludeSeedAudienceCheckbox />}
        </Grid>
        <Grid container={true} xs={12}>
          <AudienceQualityStatistics />
        </Grid>
      </Grid>
      <Divider
        sx={({ spacing }) => ({ margin: spacing(4, 0), width: "100%" })}
      />
      <Grid container={true}>
        <Grid xs={7}>
          <Typography level="title-md">
            3. Generate the Lookalike Audience
          </Typography>
          <Typography level="body-sm">
            Click the button to create your targeted audience
          </Typography>
        </Grid>
        <Grid container={true} justifyContent="flex-end" xs={5}>
          <Button
            color="primary"
            disabled={!selectedAudienceType}
            loading={generateLookalikeAudienceLoading}
            loadingPosition="start"
            onClick={
              !allowExcludingSeedAudience ||
              activatedLookalikeAudiences.length > 0
                ? generateLookalikeAudience
                : openExcludeSeedAudienceInfoDialog
            }
            startDecorator={<FontAwesomeIcon icon={faSparkles} />}
            sx={{ height: "fit-content" }}
            variant="solid"
          >
            Generate results
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

AdvertiserAudienceGenerator.displayName = "AdvertiserAudienceGenerator";

export default AdvertiserAudienceGenerator;

*/

import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { faBan } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { memo, useCallback, useState } from "react";
import { EmptyStateContainer } from "features/MediaInsightsDcr/components";
import { usePublishedMediaInsightsDcr } from "features/MediaInsightsDcr/contexts";
import { LookalikeAdvertiserAudienceGenerator } from "../../LookalikeAdvertiserAudienceGenerator";
import { RulesBasedAdvertiserAudienceGenerator } from "../../RulesBasedAdvertiserAudienceGenerator";
import {
  AdvertiserAudienceGeneratorTypeSelectionStep,
  AudienceGeneratorStepsWrapper,
} from "../components";
import { mapGenerationStepToPresentation } from "../models";
import {
  AdvertiserAudienceGeneratorStep,
  AdvertiserAudienceGeneratorType,
  useAdvertiserAudienceGenerator,
} from "..";

const AdvertiserAudienceGenerator = memo(() => {
  const {
    currentStep,
    generationSteps,
    availableAudiences: {
      computeResults: { audienceTypes = [] } = {},
      status: availableAudiencesStatus,
      error: availableAudiencesError,
      retry: retryAvailableAudiences,
    },
    generatorType,
  } = useAdvertiserAudienceGenerator();
  const [retryInProgress, setRetryInProgress] = useState(false);
  const { isDeactivated } = usePublishedMediaInsightsDcr();
  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    try {
      await retryAvailableAudiences();
    } finally {
      setRetryInProgress(false);
    }
  }, [retryAvailableAudiences, isDeactivated]);
  if (availableAudiencesStatus !== "COMPLETED") {
    return (
      <Box
        alignItems="center"
        display="flex"
        height="100%"
        justifyContent="center"
        padding="1rem"
      >
        <CircularProgress color="inherit" size="1.5rem" thickness={1} />
      </Box>
    );
  }
  if (isDeactivated || availableAudiencesError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography mt={1} variant="subtitle2">
          Audience data could not be retrieved
        </Typography>
        {!isDeactivated && (
          <LoadingButton
            color="inherit"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 2 }}
            variant="outlined"
          >
            Retry
          </LoadingButton>
        )}
      </EmptyStateContainer>
    );
  }
  if (audienceTypes.length === 0) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography mt={1} variant="subtitle2">
          There are not enough individuals in the overlap.
        </Typography>
        <Typography variant="subtitle2">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }
  return (
    <AudienceGeneratorStepsWrapper>
      <Grid container={true}>
        <Grid item={true} mb={3} xs={12}>
          <Typography fontWeight="fontWeightSemiBold" variant="h5">
            Create a new audience
          </Typography>
        </Grid>
        <Grid item={true} mb={3} xs={6}>
          <Stepper
            activeStep={generationSteps.indexOf(currentStep)}
            orientation="horizontal"
          >
            {generationSteps.map((step) => (
              <Step key={step}>
                <StepLabel>
                  {mapGenerationStepToPresentation.get(step)}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </Grid>
      {currentStep === AdvertiserAudienceGeneratorStep.SELECT_TYPE && (
        <AdvertiserAudienceGeneratorTypeSelectionStep />
      )}
      {generatorType === AdvertiserAudienceGeneratorType.RULES_BASED && (
        <RulesBasedAdvertiserAudienceGenerator />
      )}
      {generatorType === AdvertiserAudienceGeneratorType.LOOKALIKE && (
        <LookalikeAdvertiserAudienceGenerator />
      )}
    </AudienceGeneratorStepsWrapper>
  );
});

AdvertiserAudienceGenerator.displayName = "AdvertiserAudienceGenerator";

export default AdvertiserAudienceGenerator;
