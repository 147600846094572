import { type Session } from "@decentriq/core";
import { usePublishedMediaInsightsDcrDataLabDataQuery } from "@decentriq/graphql/dist/hooks";
import { type FullDataLabFragment } from "@decentriq/graphql/dist/types";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { createContext, memo, useCallback, useMemo } from "react";
import { useApiCore } from "contexts";
import { type MediaDataRoomJobHookResult } from "features/mediaDataRoom/hooks";
import { type PublisherDatasetsHashes } from "features/mediaDataRoom/models";
import { useSafeContext } from "hooks";
import { useMediaDataRoom } from "../MediaDataRoomContext/MediaDataRoomContext";
import useAdvertiserDataReport from "./useAdvertiserDataReport";
import useDatasets, { type DatasetsHookResult } from "./useDatasets";
import useInsights, { type InsightsHookResult } from "./useInsights";
import useOverlapStatistics, {
  type OverlapStatisticsHookResult,
} from "./useOverlapStatistics";

export interface MediaDataRoomInsightsDataContextValue {
  advertiserDatasetHash: string | null;
  publisherDatasetsHashes: PublisherDatasetsHashes;
  datasets: DatasetsHookResult;
  hasRequiredData: boolean;
  hasAdvertiserData: boolean;
  hasPublisherData: boolean;
  isPublisherAudienceBeingUpdated: boolean;
  retrieveDatasets: () => Promise<void>;
  insights: InsightsHookResult;
  overlapStatistics: OverlapStatisticsHookResult;
  advertiserDataReport: MediaDataRoomJobHookResult<{
    numberOfDeduplicatedRows: number;
    numberOfIngestedRows: number;
  }>;
  // TODO: discuss if such caching is really necessary
  session?: Session;
  publishedDataLab: FullDataLabFragment | null;
  dataLabDataLoading: boolean;
}

export const MediaDataRoomInsightsDataContext =
  createContext<MediaDataRoomInsightsDataContextValue | null>(null);
MediaDataRoomInsightsDataContext.displayName =
  "MediaDataRoomInsightsDataContext";

export const useMediaDataRoomInsightsData = () =>
  useSafeContext(MediaDataRoomInsightsDataContext);

export type MediaDataRoomInsightsDataWrapperProps = React.PropsWithChildren;

const MediaDataRoomInsightsDataWrapper =
  memo<MediaDataRoomInsightsDataWrapperProps>(({ children }) => {
    const {
      dataRoomId,
      driverAttestationHash,
      isDeactivated,
      isAdvertiser,
      isDataPartner,
      isPublisher,
      hasDataPartner,
      features: { insights: enableInsights },
    } = useMediaDataRoom();
    const { sessionManager } = useApiCore();
    const queryClient = useQueryClient();
    const {
      data: dataLabData,
      loading: dataLabDataLoading,
      refetch: refetchDatalabQuery,
    } = usePublishedMediaInsightsDcrDataLabDataQuery({
      skip: !isPublisher,
      variables: {
        id: dataRoomId,
      },
    });
    const wasDataLabPublishedBefore = useMemo(
      () => !!dataLabData?.publishedMediaInsightsDcr.wasDataLabPublishedBefore,
      [dataLabData]
    );
    const { data: session } = useQuery({
      enabled: driverAttestationHash != null,
      queryFn: async () => {
        return await sessionManager.get({ driverAttestationHash });
      },
      queryKey: ["ab-media", "session", driverAttestationHash],
    });
    const datasets = useDatasets({
      dataRoomId,
      driverAttestationHash,
      queryKeyPrefix: ["ab-media"],
      session,
    });
    const insights = useInsights({
      advertiserDatasetHash: datasets.data?.advertiserDatasetHash,
      dataRoomId,
      driverAttestationHash,
      publisherDatasetsHashes: datasets.data?.publisherDatasetsHashes,
      session,
      skip:
        isDeactivated ||
        !datasets.data?.hasRequiredData ||
        !enableInsights ||
        isDataPartner,
    });
    const overlapStatistics = useOverlapStatistics({
      advertiserDatasetHash: datasets.data?.advertiserDatasetHash,
      dataRoomId,
      driverAttestationHash,
      publisherDatasetsHashes: datasets.data?.publisherDatasetsHashes,
      session,
      skip: isDeactivated || !datasets.data?.hasRequiredData,
    });
    const advertiserDataReport = useAdvertiserDataReport({
      advertiserDatasetHash: datasets.data?.advertiserDatasetHash,
      dataRoomId,
      driverAttestationHash,
      session,
      skip: isDeactivated || !(hasDataPartner ? isDataPartner : isAdvertiser),
    });
    // TODO: Use proper cache invalidation instead of this
    const handleDatasetsRetrieval = useCallback(async () => {
      const queries: Promise<unknown>[] = [
        datasets.refetch(),
        queryClient.invalidateQueries({ queryKey: ["ab-media"] }),
      ];
      if (isPublisher) {
        queries.push(refetchDatalabQuery());
      }
      await Promise.all(queries);
    }, [datasets, queryClient, refetchDatalabQuery, isPublisher]);
    const contextValue = useMemo<MediaDataRoomInsightsDataContextValue>(
      () => ({
        ...datasets.data,
        advertiserDataReport,
        dataLabDataLoading,
        datasets,
        insights,
        isPublisherAudienceBeingUpdated:
          wasDataLabPublishedBefore &&
          !datasets.data?.hasPublisherData &&
          !!datasets.data?.hasAdvertiserData,
        overlapStatistics,
        publishedDataLab:
          dataLabData?.publishedMediaInsightsDcr?.publishedDataLab ?? null,
        retrieveDatasets: handleDatasetsRetrieval,
        session,
      }),
      [
        datasets,
        advertiserDataReport,
        insights,
        wasDataLabPublishedBefore,
        overlapStatistics,
        dataLabData?.publishedMediaInsightsDcr?.publishedDataLab,
        handleDatasetsRetrieval,
        session,
        dataLabDataLoading,
      ]
    );
    return (
      <MediaDataRoomInsightsDataContext.Provider value={contextValue}>
        {children}
      </MediaDataRoomInsightsDataContext.Provider>
    );
  });

MediaDataRoomInsightsDataWrapper.displayName =
  "MediaDataRoomInsightsDataWrapper";

export default MediaDataRoomInsightsDataWrapper;
