import {
  DqFloatingLabelInput,
  DqFloatingLabelSelect,
} from "@decentriq/components";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Stack } from "@mui/joy";
import { memo, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import {
  type AudienceFilter,
  MediaDataRoomActivationTab,
} from "features/mediaDataRoom/models";

interface MediaDataRoomAudiencesToolbarProps {
  onFilterChange: (value: AudienceFilter) => void;
  selectedFilter: AudienceFilter;
  onSearchChange: (value: string) => void;
  searchValue: string;
}

const MediaDataRoomAudiencesToolbar: React.FC<MediaDataRoomAudiencesToolbarProps> =
  memo(({ onFilterChange, selectedFilter, onSearchChange, searchValue }) => {
    const {
      isAdvertiser,
      isAgency,
      features: {
        lookalike: enableLookalike,
        remarketing: enableRemarketing,
        ruleBasedAudiences: enableRuleBasedAudiences,
      },
    } = useMediaDataRoom();

    const availableFilters = useMemo<
      { label: string; value: AudienceFilter }[]
    >(() => {
      const filters: { label: string; value: AudienceFilter }[] = [
        { label: "All", value: "all" },
      ];
      if (enableLookalike) {
        filters.push({ label: "AI lookalike", value: "lookalike" });
      }
      if (enableRemarketing) {
        filters.push({ label: "Remarketing audience", value: "advertiser" });
      }
      if (enableRuleBasedAudiences) {
        filters.push({ label: "Rule-based audience", value: "rulebased" });
      }
      return filters;
    }, [enableLookalike, enableRemarketing, enableRuleBasedAudiences]);

    const navigate = useNavigate();
    return (
      <Box display="flex" justifyContent="space-between" mb={1}>
        <Stack direction="row">
          <DqFloatingLabelSelect
            label="Show"
            onChange={(_e, value) => onFilterChange(value as AudienceFilter)}
            options={availableFilters}
            sx={{ minWidth: "220px" }}
            value={selectedFilter}
          />
          <DqFloatingLabelInput
            label="Search audience"
            onChange={(e) => onSearchChange(e.target.value)}
            placeholder="Search audience"
            sx={{ minWidth: "440px" }}
            value={searchValue}
          />
        </Stack>
        {(enableLookalike || enableRemarketing) &&
          (isAdvertiser || isAgency) && (
            <Button
              color="primary"
              onClick={() => navigate(MediaDataRoomActivationTab.creation)}
              startDecorator={<FontAwesomeIcon icon={faPlus} />}
              sx={{ height: "fit-content" }}
              variant="solid"
            >
              Create new audience
            </Button>
          )}
      </Box>
    );
  });

MediaDataRoomAudiencesToolbar.displayName = "MediaDataRoomAudiencesToolbar";

export default MediaDataRoomAudiencesToolbar;
