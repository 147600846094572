import { faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Typography } from "@mui/joy";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  EmptyStateContainer,
  MediaDataRoomInsightsAdvertiserAudienceSelector,
  OverlapStatistics,
  useMediaInsightsDcrData,
} from "features/MediaInsightsDcr";

const MediaDataroomDataOverlap: React.FC = () => {
  const form = useForm<{ audienceType: string }>({
    defaultValues: { audienceType: "" },
    mode: "onChange",
  });

  const {
    overlapStatistics: {
      error: overlapStatisticsError,
      retry: retryOverlapStatistics,
    },
  } = useMediaInsightsDcrData();

  if (overlapStatisticsError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography level="body-sm" mt={1}>
          Overlap could not be retrieved.
        </Typography>
        <Button
          color="secondary"
          onClick={retryOverlapStatistics}
          sx={{ marginTop: 4 }}
        >
          Retry
        </Button>
      </EmptyStateContainer>
    );
  }

  return (
    <Box mt={4}>
      <FormProvider {...form}>
        <Box width="30%">
          <MediaDataRoomInsightsAdvertiserAudienceSelector />
        </Box>
        <OverlapStatistics withSpinner={false} />
      </FormProvider>
    </Box>
  );
};

MediaDataroomDataOverlap.displayName = "MediaDataroomDataOverlap";

export default memo(MediaDataroomDataOverlap);
