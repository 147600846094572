import { ClientApiError } from "@decentriq/core";
import {
  useCreateMediaInsightsComputeJobMutation,
  useGetMediaInsightsComputeJobLazyQuery,
} from "@decentriq/graphql/dist/hooks";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { loadAsync } from "jszip";
import * as forge from "node-forge";
import { useCallback, useEffect, useMemo } from "react";
import { camelCaseToSnakeCase, logDebug, parseMediaDataRoomError } from "utils";
import {
  type MediaDataRoomJobHookPayload,
  type MediaDataRoomJobHookResult,
  type MediaDataRoomRequestKey,
} from "./models";
import useMediaDataRoomRequest from "./useMediaDataRoomRequest";

/**
 *This hook is used to create request, run it if not skipped and fetch the results of the job for using job ID and compute node name from request response. It also caches the job in API platform database.
 *
 * @param dataRoomId data room id
 * @param driverAttestationHash driver attestation hash
 * @param key `AbMediaRequest` key; name of the request to be sent
 * @param requestCreator function which provides `dataRoomIdHex`, `scopeIdHex` and needs to return body of the request for the given key
 * @param cacheKey key used to cache the job
 * @param queryKeyPrefix array with strings to prefix query key
 * @param session optional `Session`, if not provided, it will be fetched
 * @param skip if true, the job result fetching will be skipped
 * @param transform function to transform the job result, it provides the zip file in `JSZip` format
 * @returns object with the job result, error, loading state, retry function and status
 */
const useMediaDataRoomJob = <T, K extends MediaDataRoomRequestKey>({
  dataRoomId,
  driverAttestationHash,
  key,
  requestCreator,
  cacheKey,
  queryKeyPrefix,
  session,
  skip,
  transform,
}: MediaDataRoomJobHookPayload<T, K>): MediaDataRoomJobHookResult<T> => {
  const jobType = camelCaseToSnakeCase(`abMedia${key}`);
  const queryClient = useQueryClient();
  const [createMediaInsightsComputeJobMutation] =
    useCreateMediaInsightsComputeJobMutation();
  const [getMediaInsightsComputeJob] = useGetMediaInsightsComputeJobLazyQuery();
  const { data: existingJob, isLoading: existingJobLoading } = useQuery<{
    computeNodeName: string;
    jobIdHex: string;
  } | null>({
    enabled: Boolean(cacheKey) && !skip,
    queryFn: async () => {
      if (!cacheKey) {
        return null;
      }
      // TODO: disable it for now
      const existingJob: { computeNodeName: string; jobIdHex: string } | null =
        null;
      // const getJobResult = await getMediaInsightsComputeJob({
      //   variables: {
      //     input: {
      //       cacheKey,
      //       jobType,
      //       publishedDataRoomId: dataRoomId,
      //     },
      //   },
      // });
      // const mediaComputeJob = getJobResult?.data?.mediaComputeJob;
      // const existingJob = mediaComputeJob
      //   ? {
      //       computeNodeName: mediaComputeJob.computeNodeName,
      //       jobIdHex: mediaComputeJob.jobIdHex,
      //     }
      //   : null;
      return existingJob;
    },
    queryKey: [...queryKeyPrefix, "existingJob", cacheKey],
  });
  const [sendRequest, getSession] = useMediaDataRoomRequest({
    dataRoomId,
    driverAttestationHash,
    key,
    requestCreator,
  });
  const createJob = useCallback(async () => {
    const effectiveSession = session ?? (await getSession());
    const response = await sendRequest({
      options: { session: effectiveSession },
    });
    if (!("computeNodeName" in response) || !("jobIdHex" in response)) {
      throw new Error(`Invalid response for job ${key}`);
    }
    // For some reason can't just return response, TS complains
    return {
      computeNodeName: response.computeNodeName,
      jobIdHex: response.jobIdHex,
    };
  }, [sendRequest, session, getSession, key]);
  const { mutate: createJobMutation } = useMutation({
    mutationFn: async ({ cacheKey }: { cacheKey: string }) => {
      const createResponse = await createMediaInsightsComputeJobMutation({
        variables: {
          input: {
            ...(await createJob()),
            cacheKey,
            jobType,
            publishedDataRoomId: dataRoomId,
          },
        },
      });
      return createResponse.data?.mediaComputeJob;
    },
    onSuccess: (data) => {
      queryClient.setQueryData([...queryKeyPrefix, "existingJob", cacheKey], {
        computeNodeName: data?.create.record.computeNodeName,
        jobIdHex: data?.create.record.jobIdHex,
      });
    },
  });
  // If existingJob is null, we need to create a new job
  useEffect(() => {
    if (!skip && existingJob == null && !existingJobLoading && !!cacheKey) {
      createJobMutation({ cacheKey });
    }
  }, [createJobMutation, existingJob, existingJobLoading, cacheKey, skip]);
  // Polling for job status
  const {
    data: jobStatus,
    isLoading: jobStatusIsLoading,
    error: statusError,
  } = useQuery({
    enabled: Boolean(existingJob),
    queryFn: async () => {
      const effectiveSession = session ?? (await getSession());
      if (!existingJob || !effectiveSession) {
        return null;
      }
      const statusResponse = await effectiveSession.getComputationStatus(
        existingJob?.jobIdHex
      );
      const isCompleted = statusResponse.completeComputeNodeIds?.includes(
        existingJob.computeNodeName
      );
      return isCompleted ? "COMPLETED" : "COMPUTING";
    },
    queryKey: [
      ...queryKeyPrefix,
      "jobStatus",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
    refetchInterval: (query) => {
      if (existingJob && query.state.data === "COMPUTING") {
        // TODO @matyasfodor - polling interval should be configurable
        return 1000;
      }
    },
  });
  const resultQueryKey = useMemo(
    () => [
      ...queryKeyPrefix,
      "fetchResults",
      existingJob?.computeNodeName,
      existingJob?.jobIdHex,
    ],
    [queryKeyPrefix, existingJob?.computeNodeName, existingJob?.jobIdHex]
  );
  const setCacheData = useCallback(
    (data: T | undefined) => {
      queryClient.setQueryData<T | undefined>(resultQueryKey, () => data);
    },
    [queryClient, resultQueryKey]
  );
  // Query the results
  const {
    data: computeResults,
    isLoading: resultsFetchingLoading,
    error: resultsFetchingError,
  } = useQuery({
    enabled: jobStatus === "COMPLETED" && !skip,
    queryFn: async () => {
      logDebug("||| Querying results for job", key);
      try {
        if (!existingJob) {
          throw new Error("Compute job not found");
        }
        logDebug("||| Existing job found, fetching results", key);
        const result = await session?.getJobResults(
          forge.util.binary.hex.decode(existingJob.jobIdHex),
          existingJob.computeNodeName
        );
        if (!result) {
          throw new Error("No result");
        }
        const zip = await loadAsync(result);
        return await transform(zip);
      } catch (error) {
        throw parseMediaDataRoomError(error);
      }
    },
    queryKey: resultQueryKey,
  });
  const error = useMemo(() => {
    if (statusError) {
      return statusError instanceof ClientApiError
        ? statusError?.message
        : `${statusError}`;
    }
    if (resultsFetchingError) {
      return resultsFetchingError instanceof ClientApiError
        ? resultsFetchingError?.message
        : `${resultsFetchingError}`;
    }
    return undefined;
  }, [statusError, resultsFetchingError]);
  const retry = useCallback(async () => {
    if (!cacheKey || !session) {
      throw new Error("No key or session");
    }
    return createJobMutation({ cacheKey });
  }, [cacheKey, createJobMutation, session]);
  const status = useMemo(
    () =>
      jobStatusIsLoading || jobStatus !== "COMPLETED"
        ? "COMPUTING"
        : resultsFetchingLoading
          ? "FETCHING"
          : "COMPLETED",
    [jobStatus, jobStatusIsLoading, resultsFetchingLoading]
  );
  return useMemo(
    () => ({
      computeResults,
      error,
      loading: status !== "COMPLETED",
      retry,
      setCacheData,
      status,
    }),
    [computeResults, error, status, retry, setCacheData]
  );
};

export default useMediaDataRoomJob;
