import { faBan, faCircleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, CircularProgress, Typography } from "@mui/joy";
import { memo, useCallback, useState } from "react";
import {
  EmptyStateContainer,
  MediaDataRoomInsightsTabs,
} from "features/MediaInsightsDcr/components";
import {
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr/contexts";

const EmptyStateDisplay: React.FC<{ label: string }> = ({ label }) => {
  return (
    <EmptyStateContainer>
      <Typography
        level="body-sm"
        sx={{ alignItems: "center", display: "inline-flex" }}
      >
        <CircularProgress sx={{ "--CircularProgress-size": "14px", mr: 1 }} />
        {label}
      </Typography>
    </EmptyStateContainer>
  );
};

const MediaDataRoomInsights: React.FC = () => {
  const { isDeactivated, isPublisher } = usePublishedMediaInsightsDcr();
  const {
    hasRequiredData,
    datasetsLoading,
    error: fetchingError,
    isPublisherAudienceBeingUpdated,
    insights: {
      retry,
      loading: insightsLoading,
      error: insightsFetchingError,
      status: insightsFetchingStatus,
    },
    overlapStatistics: { computeResults: { isEmpty: isEmptyStatistics } = {} },
  } = useMediaInsightsDcrData();

  const [retryInProgress, setRetryInProgress] = useState(false);

  const retryComputations = useCallback(async () => {
    if (isDeactivated) return;
    setRetryInProgress(true);
    try {
      retry();
    } finally {
      setRetryInProgress(false);
    }
  }, [isDeactivated, retry]);

  if (isDeactivated || insightsFetchingError || fetchingError) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon
          fixedWidth={true}
          icon={faCircleExclamation}
          size="2x"
        />
        <Typography level="body-sm" mt={1}>
          Insights could not be retrieved
        </Typography>
        {!isDeactivated && (
          <Button
            color="secondary"
            loading={retryInProgress}
            onClick={retryComputations}
            sx={{ marginTop: 4 }}
          >
            Retry
          </Button>
        )}
      </EmptyStateContainer>
    );
  }

  if (datasetsLoading) {
    return <EmptyStateDisplay label="Connecting to the enclave" />;
  }

  if (isPublisherAudienceBeingUpdated) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          Publisher audience is being updated.
        </Typography>
        <Typography level="body-sm">
          {isPublisher
            ? "Please provision a datalab again."
            : "Activation will be available again soon."}{" "}
        </Typography>
      </EmptyStateContainer>
    );
  }

  if (!hasRequiredData) {
    return <Alert>Waiting for both parties to provision data.</Alert>;
  }

  if (insightsLoading) {
    return (
      <EmptyStateDisplay
        label={
          insightsFetchingStatus === "COMPUTING"
            ? "Computing..."
            : insightsFetchingStatus === "FETCHING"
              ? "Retrieving insights..."
              : ""
        }
      />
    );
  }

  if (isEmptyStatistics) {
    return (
      <EmptyStateContainer>
        <FontAwesomeIcon fixedWidth={true} icon={faBan} size="2x" />
        <Typography level="body-sm" mt={1}>
          There are not enough individuals in the overlap.
        </Typography>
        <Typography level="body-sm">
          Please check if the Matching ID is compatible.
        </Typography>
      </EmptyStateContainer>
    );
  }
  return <MediaDataRoomInsightsTabs />;
};

MediaDataRoomInsights.displayName = "MediaDataRoomInsights";

export default memo(MediaDataRoomInsights);
