import { usePublishedMediaDataRoomQuery } from "@decentriq/graphql/dist/hooks";
import { DataRoomKind } from "@decentriq/graphql/dist/types";
import { memo } from "react";
import { Navigate, useParams } from "react-router-dom";
import { MediaDataRoom } from "features/mediaDataRoom";
import { MediaDataRoom as LegacyMediaDataRoom } from "features/MediaInsightsDcr";
import { Main } from "layouts";

const MediaDataRoomPage: React.FC = () => {
  const { dataRoomId } = useParams();
  // TODO: Moving querying to context for now to support all media insights dcr and split on kind
  const { data, loading, error } = usePublishedMediaDataRoomQuery({
    variables: { id: dataRoomId || "" },
  });
  if (loading) {
    return null;
  }
  if (error || !data?.publishedMediaDataRoom) {
    return <Navigate replace={true} to="/datarooms" />;
  }
  const kind = data.publishedMediaDataRoom.kind;
  if (kind === DataRoomKind.AbMedia) {
    return (
      <Main>
        <MediaDataRoom data={data.publishedMediaDataRoom!} />
      </Main>
    );
  }
  return (
    <Main>
      <LegacyMediaDataRoom data={data.publishedMediaDataRoom!} />
    </Main>
  );
};

export default memo(MediaDataRoomPage);
