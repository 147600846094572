// TODO: Fix joy migration
import { testIds } from "@decentriq/utils";
import { Checkbox, FormControl, Option, Select } from "@mui/joy";
import { xor } from "lodash";
import difference from "lodash/difference";
import { memo, useCallback } from "react";

interface PermissionsSelectProps {
  options: { title: string; value: string; __typename?: string }[];
  permissions: string[];
  disabled?: boolean;
  onAddPermission: (value: string) => void;
  onRemovePermission: (value: string, title: string) => void;
  dataKey: string;
  disableSelectAll?: boolean;
  maxWidth?: string;
}

const DataRoomPermissionsSelect = ({
  disabled = false,
  options = [],
  permissions,
  onAddPermission,
  onRemovePermission,
  dataKey,
  disableSelectAll,
  maxWidth,
}: PermissionsSelectProps) => {
  const handleSelectAll = useCallback(() => {
    const permissionsValues = options.map(({ value }) => value);
    if (permissions.length !== options.length) {
      const addPermissions = difference(permissionsValues, permissions);
      addPermissions.forEach((value) => {
        onAddPermission(value);
      });
    } else {
      options.forEach(({ value, title }) => {
        onRemovePermission(value, title);
      });
    }
  }, [onAddPermission, onRemovePermission, options, permissions]);
  const handleSelectChange = useCallback(
    (event, value) => {
      if (!disabled) {
        const effectiveValue =
          typeof value === "string" ? value.split(",") : (value as string[]);
        const diff = xor(effectiveValue, permissions || []);
        const focusedValue = diff[0];
        if (!focusedValue) {
          return;
        }
        if (focusedValue === "all") {
          handleSelectAll();
          return;
        }
        if (permissions.includes(focusedValue)) {
          onRemovePermission(
            focusedValue,
            options.find((o) => o.value === focusedValue)?.title || ""
          );
          return;
        }
        onAddPermission(focusedValue);
      }
    },
    [
      options,
      permissions,
      onAddPermission,
      onRemovePermission,
      handleSelectAll,
      disabled,
    ]
  );
  const availableOptions = options.filter(
    ({ value }) => !disabled || permissions.includes(value)
  );
  return (
    <FormControl>
      <Select<string[]>
        data-testid={`${testIds.dataroom.dataRoomPermissionSelect.helper}${dataKey}`}
        disabled={availableOptions.length === 0}
        multiple={true}
        onChange={handleSelectChange}
        placeholder={`No ${dataKey}s ${
          availableOptions.length === 0 ? "available" : "selected"
        }`}
        renderValue={(selected) => {
          if (selected.length === 1) {
            const selectedId = selected[0].value;
            const node = options.find(
              ({ value: nodeId }) => nodeId === selectedId
            );
            const { __typename = "", title } = node || {};
            return ["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
              ? `${title} — Statistics`
              : title;
          } else {
            const selectedPermissions = options
              .filter(({ value: id }) =>
                selected.map(({ value }) => value).includes(id)
              )
              .map(({ title }) => title);
            return `${selectedPermissions.length} ${dataKey}s`;
          }
        }}
        slotProps={{ listbox: { variant: "outlined" } }}
        sx={{ backgroundColor: "transparent" }}
        value={permissions}
        variant="plain"
      >
        {!disableSelectAll && options.length > 1 && !disabled ? (
          <Option value="all">
            <Checkbox
              checked={options.length === permissions.length}
              data-testid={testIds.dataroom.dataRoomPermissionSelect.selectAll}
              disabled={disabled}
            />
            Select all
          </Option>
        ) : null}
        {availableOptions.map(({ __typename = "", value, title }) => (
          <Option
            data-testid={`${testIds.dataroom.dataRoomPermissionSelect.helper}${title}`}
            disabled={disabled}
            key={value}
            value={value}
          >
            {!disabled && <Checkbox checked={permissions.includes(value)} />}
            {["DraftMatchNode", "PublishedMatchNode"].includes(__typename)
              ? `${title} — Statistics`
              : title}
          </Option>
        ))}
      </Select>
    </FormControl>
  );
};

DataRoomPermissionsSelect.displayName = "DataRoomPermissionsSelect";

export default memo(DataRoomPermissionsSelect);
