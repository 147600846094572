import { Chip } from "@mui/joy";
import { memo, useMemo } from "react";
import { replaceDecentriqOrgName } from "features/legacyMediaDataRoom";
import {
  type Audience,
  audienceStatusPresentationMap,
  useMediaDataRoom,
} from "features/mediaDataRoom";
import { useAudiences } from "features/mediaDataRoom/wrappers";

interface AudienceStatusLabelProps {
  id: string;
  status: Audience["status"];
}

const AudienceStatusLabel: React.FC<AudienceStatusLabelProps> = ({
  id,
  status,
}) => {
  const {
    audienceSizes: { audienceSizes },
  } = useAudiences();
  const { publisherOrganization } = useMediaDataRoom();
  const audienceStatusLabel = useMemo(() => {
    return `Available to ${replaceDecentriqOrgName(publisherOrganization?.name, "Publisher")}`;
  }, [publisherOrganization?.name]);
  return (
    <Chip
      component="span"
      size="md"
      sx={(theme) => ({
        borderRadius: theme.radius.sm,
      })}
    >
      {status === "published"
        ? audienceStatusLabel
        : audienceSizes[id]?.loading
          ? "Computing"
          : audienceStatusPresentationMap.get(status)}
    </Chip>
  );
};

AudienceStatusLabel.displayName = "AudienceStatusLabel";

export default memo(AudienceStatusLabel);
